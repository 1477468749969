import AllTemplatesPage from "../pages/template-management/AllTemplatesPage";
import UpdateTemplatePage from "../pages/template-management/UpdateTemplatePage";
import UploadTemplatePage from "../pages/template-management/UploadTemplatePage";
import ViewTemplatePage from "../pages/template-management/ViewTemplatePage";



const templateManagementRoutes = [
  {
    path: 'all-templates',
    element: <AllTemplatesPage />,
  },
  {
    path: 'all-templates/upload-template',
    element: <UploadTemplatePage />,
  },
  {
    path: 'all-templates/update-template/:id',
    element: <UpdateTemplatePage />,
  },
  {
    path: 'all-templates/view-template/:id',
    element: <ViewTemplatePage />,
  },

];

export default templateManagementRoutes;
