import React, { useEffect, useState } from "react";
import BreadCrums from "../../common/BreadCrums";
import ButtonForm from "../buttons/ButtonForm";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  clearMessage,
  getClientByID,
  updateClient,
} from "../../store/adminSlice";
import { toast } from "react-toastify";

export default function UpdateClientForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, message, error, statusCode, data } = useSelector(
    (state) => state.admin
  );

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    techStack: "",
  });

  useEffect(() => {
    if (id) {
      dispatch(getClientByID(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (data && data?._id === id) {
      setFormData({
        name: data.name || "",
        email: data.email || "",
        phone: data.phone || "",
        address: data.address || "",
        techStack: data.techStack || "",
      });
    }
  }, [data, id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const { name, email, phone, address, techStack } = formData;

    if (!name || !email || !phone || !address || !techStack) {
      toast.error("All fields are required!");
      return false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      toast.error("Please enter a valid email address!");
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formDataToSend = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        address: formData.address,
        techStack: formData.techStack,
      };

      dispatch(updateClient({ id, data: formDataToSend }));
    }
  };

  useEffect(() => {
    if (statusCode === 200) {
      toast.success(message);
      navigate(-1);
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [loading, message, error, statusCode, dispatch, navigate]);

  return (
    <>
      <div className="">
        <div className="flex justify-between  items-center py-5">
          <BreadCrums
            breadCrum={[
              {
                name: "Admin Dashboard",
                path: "/admin-dashboard/",
              },
              {
                name: "Clients",
                path: "/admin-dashboard/list-clients",
              },
              {
                name: "Update Client",
              },
            ]}
          />
        </div>
      </div>
      <div className="w-full bg-white dark:bg-secondaryDarkBg rounded-xl p-3 mb-4  border-l-4 border-usetheme">
        <h1 className=" text-[#18120F] dark:text-white text-lg md:text-lg font-semibold p-1">
          Update Client
        </h1>
        <form
          className="w-full border border-[#EBF0ED] rounded-lg p-3 uppercase space-y-2"
          onSubmit={handleSubmit}
        >
          {/* Name and email */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold flex items-center gap-2">
                Name
              </label>

              <input
                name="name"
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="khan"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>

            {/* Email */}
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold flex gap-2">
                Email{" "}
              </label>
              <input
                name="email"
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="email"
                placeholder="netchain@gmail.com"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Phone and techstack */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Phone
              </label>
              <input
                name="phone"
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="+923038772575"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Tech Stack
              </label>
              <input
                name="techStack"
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="HTML, CSS"
                value={formData.techStack}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* address */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Address
              </label>
              <input
                name="address"
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="Peshawar, Pakistan"
                value={formData.address}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="flex justify-end">
            <ButtonForm
              onClick={handleSubmit}
              loading={loading}
              text="Update Client"
            />
          </div>
        </form>
      </div>
    </>
  );
}
