import AllVisitorsPage from "../pages/visitors-management/AllVisitorsPage";
import VisitorCreationPage from "../pages/visitors-management/VisitorCreationPage";
import VisitorUpdationPage from "../pages/visitors-management/VisitorUpdationPage";




const visitorsManagementRoutes = [
  {
    path: 'talent-pool',
    element: <AllVisitorsPage />,
  },
  {
    path: 'talent-pool/add-talent',
    element: <VisitorCreationPage />,
  },
  {
    path: 'talent-pool/update-talent/:id',
    element: <VisitorUpdationPage />,
  },

];

export default visitorsManagementRoutes;
