import React from "react";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";
import UpdateInvoiceForm from "../../components/invoices-management-components/UpdateInvoiceForm";

export default function UpdateInvoicePage() {

  return (
    <>
      <AdminDashboardLayout>
        <div className="">
          <UpdateInvoiceForm />
        </div>
      </AdminDashboardLayout>
    </>
  );
}
