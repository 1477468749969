import React from "react";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";
import VisitorCreationForm from "../../components/visitors-management-components/VisitorCreationForm";

export default function VisitorCreationPage() {
  return (
    <>
      <AdminDashboardLayout>
        <div className="">
          <VisitorCreationForm />
        </div>
      </AdminDashboardLayout>
    </>
  );
}
