import React, { useEffect, useState } from "react";

import CompanySettingsForm from "./CompanySettingsForm";
import ColorPicker from "./ColorPicker";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { clearMessage } from "../../store/adminSlice";

export default function Settings() {
  const dispatch = useDispatch();
  const { appLoading, appMessage, appError, data } = useSelector((state) => state.admin);
  const [openTab, setOpenTab] = useState(2);

  const [themeColor, setThemeColor] = useState("#ed1c24");

  const handleColorChange = (newColor) => {
    setThemeColor(newColor);
    // Dynamically update the theme color
    document.documentElement.style.setProperty("--tw-colors-usetheme", newColor);
    localStorage.setItem("themeColor", newColor);
  };

  useEffect(() => {

    const savedColor = localStorage.getItem("themeColor") || "#ed1c24";
    setThemeColor(savedColor);
    document.documentElement.style.setProperty("--tw-colors-usetheme", savedColor);
  }, []);

  useEffect(() => {
    if (appMessage) {
      toast.success(appMessage);
      // navigate(-1);
      dispatch(clearMessage())
    }
    if (appError) {
      toast.error(appError);
    }
    dispatch(clearMessage());
  }, [appMessage, appError]);

  return (
    <div className="bg-gray-100 dark:bg-primaryDarkBg font-sans ">
      <div className="mb-4">
        <div className="max-w-screen-2xl">
          <div className="mb-4 w-fit flex space-x-4 p-1 bg-white dark:bg-secondaryDarkBg rounded-lg shadow-md">

            <button
              onClick={() => setOpenTab(2)}
              className={`text-sm md:text-base py-1 px-2 rounded-md focus:outline-none transition-all duration-300 ${
                openTab === 2 ? "bg-usetheme text-white" : "bg-white text-gray-700"
              }`}
            >
              App Settings
            </button>
            <button
              onClick={() => setOpenTab(3)}
              className={`text-sm md:text-base py-1 px-2 rounded-md focus:outline-none transition-all duration-300 ${
                openTab === 3 ? "bg-usetheme text-white" : "bg-white text-gray-700"
              }`}
            >
              Design Settings
            </button>
          </div>


          <div
            className={`transition-all duration-300 bg-white dark:bg-secondaryDarkBg p-2 md:p-4 rounded-lg shadow-md border-l-4 ${
              openTab === 2 ? "block border-usetheme" : "hidden"
            }`}
          >
            <CompanySettingsForm />
          </div>

          <div
            className={`transition-all duration-300 bg-white dark:bg-secondaryDarkBg p-2 md:p-4 rounded-lg shadow-md border-l-4 ${
              openTab === 3 ? "block border-usetheme" : "hidden"
            }`}
          >
            <ColorPicker onColorChange={handleColorChange} />
          </div>
        </div>
      </div>
    </div>
  );
}
