import PublicInvoiceViewPage from "../pages/invoices-management/PublicInvoiceViewPage";


const PublicViewRoutes = [
  {
    path: 'view/:number/:secret',
    element: <PublicInvoiceViewPage />,
  },
];

export default PublicViewRoutes;
