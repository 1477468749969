import React from "react";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";
import BreadCrums from "../../common/BreadCrums";
import Settings from "../../components/setting-pages-components/Settings";

export default function SettingPage() {
  return (
    <>
      <AdminDashboardLayout>
        <div className="">
          <div className="">
            <div className="flex justify-between  items-center py-5">
              <BreadCrums
                breadCrum={[
                  {
                    name: "Admin Dashboard",
                    path: "/admin-dashboard/",
                  },
                  {
                    name: "Settings",
                    // path: "/admin-dashboard/settings",
                  },
                ]}
              />
            </div>
          </div>
          <Settings />
        </div>
      </AdminDashboardLayout>
    </>
  );
}
