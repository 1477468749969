import React from "react";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";
import AllVisitors from "../../components/visitors-management-components/AllVisitors";

export default function AllVisitorsPage() {
  return (
    <>
      <AdminDashboardLayout>
        <div className="">
          <AllVisitors />
        </div>
      </AdminDashboardLayout>
    </>
  );
}
