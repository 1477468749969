import React from "react";
import UpdateTemplateForm from "../../components/template-management-component/UpdateTemplateForm";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";

export default function UpdateTemplatePage() {
  return (
    <>
      <AdminDashboardLayout>
        <div className="">
          <UpdateTemplateForm />
        </div>
      </AdminDashboardLayout>
    </>
  );
}
