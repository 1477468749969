import React from "react";
import AllUsers from "../../components/user-management-components/AllUsers";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";
import BreadCrums from "../../common/BreadCrums";
import { Link } from "react-router-dom";
import ButtonLink from "../../components/buttons/ButtonLink";

export default function AllUsersPage() {
  return (
    <>
      <AdminDashboardLayout>
        <div className="">
          <div className="">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center py-5 space-y-4 sm:space-y-0">
              <BreadCrums
                breadCrum={[
                  {
                    name: "Admin Dashboard",
                    path: "/admin-dashboard/",
                  },
                  {
                    name: "All Users",
                  },
                ]}
              />

                <ButtonLink to="/admin-dashboard/all-users/new-users" text="Non Active Users" />

            </div>
          </div>
          <AllUsers />
        </div>
      </AdminDashboardLayout>
    </>
  );
}
