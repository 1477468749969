import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getTemplateByID } from "../../store/adminSlice";
import BreadCrums from "../../common/BreadCrums";
import ButtonLink from "../buttons/ButtonLink";
import Loader from "../../common/Loader";

export default function TemplateViewCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, error, data } = useSelector((state) => state.admin);

  useEffect(() => {
    if (id) {
      dispatch(getTemplateByID(id));
    }
  }, [id, dispatch]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <Loader />
      </div>
    );
  }

  return (
    <>
      {/* Breadcrumb and Navigation */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center py-5 space-y-4 sm:space-y-0">
        <BreadCrums
          breadCrum={[
            { name: "Admin Dashboard", path: "/admin-dashboard/" },
            {
              name: "Manage Templates",
              path: "/admin-dashboard/all-templates",
            },
            {
              name: "View Template",
            },
          ]}
        />
        <ButtonLink onClick={() => navigate(-1)} to="" text="Go Back" />
      </div>

      {/* Main Content */}
      <div className="flex justify-center items-center">
        <div className="w-full max-w-4xl">
          {error && (
            <div className="text-center text-red-500">Error: {error}</div>
          )}

          {/* PDF Preview */}
          {data?.pdfTemplate && !loading ? (
            <div className="flex justify-center">
              <div className="w-full max-w-[800px]">
                <iframe
                  className="w-full border-0"
                  src={`${data?.pdfTemplate}#toolbar=0&navpanes=0&scrollbar=0`}
                  frameBorder="0"
                  title={data?.name}
                  style={{
                    maxWidth: "100%",
                    height: "80vh",
                  }}
                  allowFullScreen
                />
              </div>
            </div>
          ) : (
            <div className="text-center text-gray-500">
              No PDF Template Available
            </div>
          )}
        </div>
      </div>
    </>
  );
}
