import AllTemplatesPage from "../pages/contract-management/AllTemplatesPage";
import CreateContractPage from "../pages/contract-management/CreateContractPage";
import SingleSubmittedContractViewPage from "../pages/contract-management/SingleSubmittedContractViewPage";
import SubmittedContractsPage from "../pages/contract-management/SubmittedContractsPage";
import UpdateSubmittedContractPage from "../pages/contract-management/UpdateSubmittedContractPage";




const contractManagementRoutes = [
  {
    path: 'contract-templates',
    element: <AllTemplatesPage/>,
  },
  {
    path: 'contracts',
    element: <SubmittedContractsPage/>,
  },
  {
    path: 'contracts/view/:id',
    element: <SingleSubmittedContractViewPage/>,
  },
  {
    path: 'contracts/update/:id',
    element: <UpdateSubmittedContractPage/>,
  },
  {
    path: 'contract-templates/create-contract/:templateID/:templateName',
    element: <CreateContractPage />,
  },

];

export default contractManagementRoutes;
