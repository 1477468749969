import React, { useEffect, useRef, useState } from "react";
import Loader from "../../common/Loader";
import {
  clearMessage,
  deleteInvoice,
  getInvoices,
  searchInvoice,
} from "../../store/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BreadCrums from "../../common/BreadCrums";
import ButtonLink from "../buttons/ButtonLink";
import DeletePopup from "../../common/DeletePopup";
import { toast } from "react-toastify";
import {
  MdExpandLess,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import Pagination from "../../common/Pagination";
import ActionIconButton from "../buttons/ActionIconButton";

export default function AllInvoicesTable() {
  const dispatch = useDispatch();
  const { loading, message, error, data, statusCode , appCoreSetting } = useSelector(
    (state) => state.admin
  );

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(appCoreSetting?.coreSettings?.itemsPerPage);
  const [sorting, setSorting] = useState(-1);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

  const [expandedRow, setExpandedRow] = useState(null);
  const detailsRef = useRef([]);

  useEffect(() => {
    if (data?.invoices?.length >= 0) {
      setExpandedRow(data?.invoices[0]?._id);
    }
  }, [data]);
  const toggleRow = (id) => {
    setExpandedRow((prev) => (prev === id ? null : id));
  };

  const getRowHeight = (index) => {
    if (
      detailsRef?.current[index] &&
      expandedRow === data?.invoices[index]._id
    ) {
      return `${detailsRef?.current[index].scrollHeight}px`;
    }
    return "0px";
  };

  const handleDeleteClick = (id) => {
    setSelectedInvoiceId(id);
    setShowDeletePopup(true);
  };

  const handleDeleteConfirmation = () => {
    dispatch(deleteInvoice(selectedInvoiceId)).then(() => {
      dispatch(
        getInvoices({ page: currentPage, limit: itemsPerPage, sort: sorting })
      );
    });
    setShowDeletePopup(false);
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false);
  };

  const handleSearch = (e) => {
    const query = e.target.value.trim();
    setSearchQuery(query);

    // Clear the previous timeout if a new search is triggered
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (e.key === "Enter") {
      if (query === "") {
        dispatch(
          getInvoices({ page: currentPage, limit: itemsPerPage, sort: sorting })
        );
      } else {
        dispatch(searchInvoice(query));
      }
    } else {
      const timeout = setTimeout(() => {
        if (query === "") {
          dispatch(
            getInvoices({
              page: currentPage,
              limit: itemsPerPage,
              sort: sorting,
            })
          );
        } else {
          dispatch(searchInvoice(query));
        }
      }, 1000);

      setSearchTimeout(timeout);
    }
  };

  useEffect(() => {
    dispatch(
      getInvoices({ page: currentPage, limit: itemsPerPage, sort: sorting })
    );
  }, [currentPage, itemsPerPage]);

  const totalPages = data?.pagination?.totalPages || 0;

  // pagination changes
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const handlePageSelectChange = (event) => {
    const selectedPage = parseInt(event.target.value, 10);
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    if (message === "Invoice deleted successfully") {
      toast.success(message);
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [loading, message, error]);

  return (
    <>
      <div className="">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center py-5 space-y-4 sm:space-y-0">
          <BreadCrums
            breadCrum={[
              {
                name: "Admin Dashboard",
                path: "/admin-dashboard/",
              },
              {
                name: "Manage Invoices",
              },
            ]}
          />
          {/* <Link
            to="create-invoice"
            className="text-white bg-usetheme text-xs md:text-sm font-semibold py-[7px] md:py-[10px] px-5 md:px-6 rounded-lg"
          >
            Add New Invoice
          </Link> */}
          <ButtonLink to="create-invoice" text="Add New Invoice" />
        </div>
        <div className="relative flex items-center w-full">
          <img
            src="/assets/icons/search.svg"
            alt=""
            className="absolute left-3 text-[#C19A6B]"
          />
          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white dark:bg-secondaryDarkBg dark:text-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-[14px]"
            type="search"
            placeholder="Search Invoices by number or reciever name .."
            onChange={handleSearch}
            onKeyDown={handleSearch}
          />
        </div>
      </div>
      <div className="mt-5">
        <div className="hidden lg:block overflow-x-auto lg:max-w-[56rem] xl:min-w-full">
          <table className="min-w-full bg-white dark:bg-secondaryDarkBg rounded-lg shadow-lg overflow-hidden">
            {/* head */}
            <thead>
              <tr className="bg-usetheme text-white">
                <th className="py-3 px-6 text-left text-sm font-medium tracking-wider">
                  Number
                </th>
                <th className="py-3 px-6 text-left text-sm font-medium tracking-wider">
                  Reciever
                </th>
                {/* <th className="py-3 px-6 text-left text-sm font-medium tracking-wider">
                  Role
                </th> */}
                <th className="py-3 px-6 text-left text-sm font-medium tracking-wider">
                  Date
                </th>
                <th className="py-3 px-6 text-left text-sm font-medium tracking-wider">
                  Bonus
                </th>
                <th className="py-3 px-6 text-left text-sm font-medium tracking-wider">
                  Basic Pay
                </th>
                <th className="py-3 px-6 text-left text-sm font-medium tracking-wider">
                  Net Total
                </th>
                <th className="py-3 px-6 text-left text-sm font-medium tracking-wider">
                  Charges
                </th>
                <th className="py-3 px-6 text-left text-sm font-medium tracking-wider">
                  Gross Total
                </th>
                <th className="py-3 px-6 text-left text-sm font-medium tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {loading ? (
                <tr>
                  <td colSpan="10">
                    <div className="flex justify-center py-4">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : data?.invoices?.length > 0 ? (
                data?.invoices?.map((inc) => (
                  <tr className="hover:bg-gray-100 dark:hover:bg-primaryDarkBg">
                    <td className="py-4 px-6 text-sm text-gray-700 dark:text-white">
                      #{inc.number}
                    </td>
                    <td className="py-4 px-6 text-sm text-gray-700 dark:text-white">
                      {inc.to}
                    </td>
                    {/* <td className="py-4 px-6 text-sm text-gray-700 dark:text-white">
                      {inc.toRoleLabel}
                    </td> */}
                    <td className="py-4 px-6 text-sm text-gray-700 dark:text-white">
                      {inc.paymentMonth}
                    </td>
                    <td className="py-4 px-6 text-sm text-gray-700 dark:text-white">
                      ${inc.bonus}
                    </td>
                    <td className="py-4 px-6 text-sm text-gray-700 dark:text-white">
                      ${Number(inc?.basicPay)}
                    </td>
                    <td className="py-4 px-6 text-sm text-gray-700 dark:text-white">
                      ${Number(inc?.basicPay || 0) + (inc?.bonus || 0)}
                    </td>
                    <td className="py-4 px-6 text-sm text-gray-700 dark:text-white">
                      ${inc.charges}
                    </td>
                    <td className="py-4 px-6 text-sm text-gray-700 dark:text-white">
                      ${Number(inc?.basicPay || 0) + (inc?.charges || 0)}
                    </td>
                    <td className="mt-2 px-2 md:px-3  flex items-center gap-2">
                      <ActionIconButton
                        to={`update-invoice/${inc._id}`}
                        iconPath="/assets/icons/edit-icon.svg"
                        alt="edit"
                      />
                      <ActionIconButton
                        to={`view-invoice/${inc._id}`}
                        iconPath="/assets/icons/view-icon.svg"
                        alt="view"
                      />
                      <ActionIconButton
                        onClick={() => handleDeleteClick(inc._id)}
                        iconPath="/assets/icons/delete-icon.svg"
                        alt="delete"
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10">
                    <div className="text-center dark:text-white font-bold tracking-wide py-2">
                      No Invoices Found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="lg:hidden mb-4">
          {loading ? (
            <div className="flex justify-center items-center py-2">
              <Loader />
            </div>
          ) : data?.invoices?.length > 0 ? (
            data?.invoices?.map((inc, index) => (
              <div key={inc._id} className="mt-4">
                {/* First Row - Name */}
                <div
                  onClick={() => toggleRow(inc._id)}
                  className={`${
                    expandedRow === inc._id
                      ? "rounded-tl-xl rounded-tr-xl"
                      : "rounded-xl"
                  } cursor-pointer flex justify-between items-center px-4 py-2 bg-usetheme text-white text-sm `}
                >
                  <div className="font-semibold">Reciever</div>
                  <div>{inc.to}</div>
                  <div>
                    {expandedRow === inc._id ? (
                      <MdExpandLess size={24} />
                    ) : (
                      <MdExpandLess className="rotate-180" size={24} />
                    )}
                  </div>
                </div>

                {/* Transition Wrapper */}
                <div
                  ref={(el) => (detailsRef.current[index] = el)}
                  style={{
                    maxHeight: getRowHeight(index),
                    transition:
                      "max-height 0.3s ease-out, opacity 0.3s ease-out",
                    overflow: "hidden",
                    opacity: expandedRow === inc._id ? 1 : 0,
                  }}
                >
                  {/* Details Rows - Only visible when expandedRow is active */}
                  <div className="flex justify-between bg-white dark:bg-secondaryDarkBg px-4 py-2 text-xxs border-b">
                    <div className="font-semibold dark:text-white">Number</div>
                    <div className="dark:text-white">#{inc.number}</div>
                  </div>
                  <div className="flex justify-between bg-white dark:bg-secondaryDarkBg px-4 py-2 text-xxs border-b">
                    <div className="font-semibold dark:text-white">Date</div>
                    <div className="dark:text-white">{inc.paymentMonth}</div>
                  </div>
                  <div className="flex justify-between bg-white dark:bg-secondaryDarkBg px-4 py-2 text-xxs border-b">
                    <div className="font-semibold dark:text-white">Bonus</div>
                    <div className="dark:text-white">${inc.bonus}</div>
                  </div>
                  <div className="flex justify-between bg-white dark:bg-secondaryDarkBg px-4 py-2 text-xxs border-b">
                    <div className="font-semibold dark:text-white">
                      Basic Pay
                    </div>
                    <div className="dark:text-white">
                      ${Number(inc?.basicPay)}
                    </div>
                  </div>
                  <div className="flex justify-between bg-white dark:bg-secondaryDarkBg px-4 py-2 text-xxs border-b">
                    <div className="font-semibold dark:text-white">
                      Net Total
                    </div>
                    <div className="font-semibold dark:text-white">
                      ${Number(inc?.basicPay || 0) + (inc?.bonus || 0)}
                    </div>
                  </div>
                  <div className="flex justify-between bg-white dark:bg-secondaryDarkBg px-4 py-2 text-xxs border-b">
                    <div className="font-semibold dark:text-white">Charges</div>
                    <div className="dark:text-white">${inc.charges}</div>
                  </div>
                  <div className="flex justify-between bg-white dark:bg-secondaryDarkBg px-4 py-2 text-xxs border-b">
                    <div className="font-semibold dark:text-white">
                      Gross Total
                    </div>
                    <div className="font-semibold dark:text-white">
                      ${Number(inc?.basicPay || 0) + (inc?.charges || 0)}
                    </div>
                  </div>
                  <div className="rounded-bl-xl rounded-br-xl flex justify-between items-center bg-white dark:bg-secondaryDarkBg px-4 py-2 text-xxs">
                    <div className="font-semibold dark:text-white">Actions</div>
                    <div className="flex gap-2">
                      <Link to={`update-invoice/${inc._id}`}>
                        <img
                          className="h-8 w-8"
                          src="/assets/icons/edit.svg"
                          alt="Edit"
                        />
                      </Link>
                      <Link to={`view-invoice/${inc._id}`}>
                        <img
                          className="h-8 w-8"
                          src="/assets/icons/view.svg"
                          alt="View"
                        />
                      </Link>
                      <Link onClick={() => handleDeleteClick(inc._id)}>
                        <img
                          className="h-8 w-8"
                          src="/assets/icons/delete.svg"
                          alt="Delete"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center dark:text-white font-bold tracking-wide py-2">
              No Invoices Found
            </div>
          )}
        </div>
      </div>

      {/* Pagination Controls */}
      {!loading && data?.invoices?.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          handlePageSelectChange={handlePageSelectChange}
        />
      )}

      {showDeletePopup && (
        <DeletePopup
          isVisible={showDeletePopup}
          onDeleteConfirmation={handleDeleteConfirmation} // Directly pass the function
          onDeleteCancel={handleDeleteCancel}
        />
      )}
    </>
  );
}
