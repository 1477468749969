import React from "react";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";

const Pagination = ({
  currentPage,
  totalPages,
  handlePageChange,
  handlePageSelectChange,
}) => {
  const renderPageNumbers = () => {
    const pages = [];

    if (totalPages <= 5) {
      // When total pages are less than or equal to 5, render page buttons directly
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-3 py-1 border rounded mx-1 ${
              currentPage === i ? "bg-usetheme text-white" : "bg-white dark:bg-secondaryDarkBg dark:text-white"
            }`}
          >
            {i}
          </button>
        );
      }
    } else {
      // When total pages are more than 5, render ellipsis and page range
      const start = Math.max(1, currentPage - 2);
      const end = Math.min(totalPages, currentPage + 2);

      if (start > 1) {
        pages.push(
          <button
            key={1}
            onClick={() => handlePageChange(1)}
            className="px-3 py-1 border rounded bg-white dark:bg-secondaryDarkBg"
          >
            1
          </button>
        );

        if (start > 2) pages.push(<span key="ellipsis-start">...</span>);
      }

      for (let i = start; i <= end; i++) {
        pages.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-3 py-1 border rounded mx-1 ${
              currentPage === i ? "bg-usetheme text-white" : "bg-white dark:bg-secondaryDarkBg"
            }`}
          >
            {i}
          </button>
        );
      }

      if (end < totalPages) {
        if (end < totalPages - 1)
          pages.push(<span key="ellipsis-end">...</span>);
        pages.push(
          <button
            key={totalPages}
            onClick={() => handlePageChange(totalPages)}
            className="px-3 py-1 border rounded bg-white dark:bg-secondaryDarkBg"
          >
            {totalPages}
          </button>
        );
      }
    }

    return pages;
  };

  return (
    <div className="flex justify-center mt-4">
      {/* Only render pagination if there are more than 1 pages */}
      {totalPages > 1 && (
        <>
          {totalPages <= 5 ? (
            // Show page buttons directly when total pages <= 5
            renderPageNumbers()
          ) : (
            // Show pagination with select dropdown when total pages > 5
            <>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`px-3 py-1 border rounded-lg bg-white dark:bg-secondaryDarkBg dark:text-white ${
                  currentPage === 1 ? "hidden" : ""
                }`}
              >
                <MdKeyboardDoubleArrowLeft />
              </button>
              <select
                value={currentPage}
                onChange={handlePageSelectChange}
                className="mx-2 border rounded-lg p-2 dark:bg-secondaryDarkBg dark:text-white"
              >
                {[...Array(totalPages)].map((_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className={`px-3 py-1 border rounded-lg bg-white dark:bg-secondaryDarkBg dark:text-white ${
                  currentPage === totalPages ? "hidden" : ""
                }`}
              >
                <MdKeyboardDoubleArrowRight />
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Pagination;
