import React from "react";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";
import AllContractTemplates from "../../components/contract-management-components/AllContractTemplates";

export default function AllTemplatesPage() {
  return (
    <>
      <AdminDashboardLayout>
        <AllContractTemplates />
      </AdminDashboardLayout>
    </>
  );
}
