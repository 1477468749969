import React from 'react'
import AdminDashboardLayout from '../../layout/AdminDashboardLayout'
import UpdateClientForm from '../../components/clients-management-components/UpdateClientForm'

export default function UpdateClientPage() {
  return (
    <>
    <AdminDashboardLayout>
        <UpdateClientForm />
    </AdminDashboardLayout>
    </>
  )
}
