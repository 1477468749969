import AddNewClientPage from "../pages/client-management/AddNewClientPage";
import AllClientsPage from "../pages/client-management/AllClientsPage";
import UpdateClientPage from "../pages/client-management/UpdateClientPage";



const clientManagementRoutes = [
  {
    path: 'list-clients',
    element: <AllClientsPage />,
  },
  {
    path: 'list-clients/add-client',
    element: <AddNewClientPage />,
  },
  {
    path: 'list-clients/update-client/:id',
    element: <UpdateClientPage />,
  },

];

export default clientManagementRoutes;
