import React from "react";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";
import CreateInvoiceForm from "../../components/invoices-management-components/CreateInvoiceForm";

export default function CreateInvoicePage() {
  return (
    <>
      <AdminDashboardLayout>
        <div className="">

          <CreateInvoiceForm />
        </div>
      </AdminDashboardLayout>
    </>
  );
}
