import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  deleteUser,
  updateUserStatus,
} from "../../store/adminSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonLink from "../buttons/ButtonLink";
import DeletePopup from "../../common/DeletePopup";

export default function NewUsersCard({ item, onUserDeleted }) {
  const dispatch = useDispatch();

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const handleDeleteClick = (id) => {
    setSelectedUserId(id);
    setShowDeletePopup(true);
  };

  const handleDeleteConfirmation = () => {
    dispatch(deleteUser(selectedUserId)).then(() => {
      onUserDeleted(selectedUserId);
    });
    setShowDeletePopup(false);
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false);
  };

  return (
    <>
      <div className="flex gap-2 ">
        <div className="flex flex-col gap-3 w-full  bg-white dark:bg-secondaryDarkBg rounded-xl p-3">
          <div className="border border-[#EBF0ED] rounded-lg px-3 py-2">
            <div className="flex items-center gap-2 "></div>
            <div>
              <div className="flex flex-col sm:flex-row justify-between my-2">
                <p className="text-[#6B6B6B] dark:text-white text-xs font-semibold">
                  Username :
                </p>
                <p className="float-end text-[12px] font-semibold text-[#18120F] dark:text-white">
                  @{item?.username}
                </p>
              </div>
              <hr />
              <div className="flex flex-col sm:flex-row justify-between my-2">
                <p className="text-[#6B6B6B] dark:text-white text-xs font-semibold">
                  Email :
                </p>
                <p className="float-end text-[12px] font-semibold text-[#18120F] dark:text-white">
                  {item?.email}
                </p>
              </div>
              <hr />
              <div className="flex justify-end items-center gap-1 my-1 mt-2">
                <ButtonLink
                  onClick={() =>
                    dispatch(updateUserStatus({ id: item._id, isActive: true }))
                  }
                  text="Approve"
                  padding="px-3 py-2"
                  activeState="bg-white dark:bg-secondaryDarkBg text-black dark:text-white"
                  hoverState="hover:bg-usetheme dark:hover:bg-usetheme hover:text-white transition duration-300"
                />

                <ButtonLink
                  onClick={() => handleDeleteClick(item._id)}
                  text="Delete"
                  padding="px-3 py-2"
                  activeState="bg-usetheme text-white"
                />
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Delete Confirmation Popup */}
      {showDeletePopup && (
        <DeletePopup
          isVisible={showDeletePopup}
          onDeleteConfirmation={handleDeleteConfirmation}
          onDeleteCancel={handleDeleteCancel}
        />
      )}
    </>
  );
}
