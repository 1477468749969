import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, forgotPassword } from "../store/adminSlice";
import { toast } from "react-toastify";
import { applyThemeMode, handleEnterSubmit } from "../constant";

export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, message, error, statusCode, appCoreSetting } = useSelector(
    (state) => state.admin
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  // for password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };


  const [themeColor, setThemeColor] = useState("#ed1c24");


  useEffect(() => {
    // Load saved color from localStorage when the page loads
    const savedColor = localStorage.getItem("themeColor") || "#ed1c24"; // Default if not found
    setThemeColor(savedColor);
    document.documentElement.style.setProperty("--tw-colors-usetheme", savedColor);
    applyThemeMode(appCoreSetting?.coreSettings?.themeMode)
  }, []);

  const handleForgotPassword = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email === "") {
      toast.error("Please Fill All the Fields ...");
    }  else {
      dispatch(forgotPassword(email));
    }
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      navigate("/");
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [loading, message, error]);

  return (
    <>
      <div className=" h-[100vh] flex justify-center items-center px-4 md:px-0 bg-[#FFFAF4] dark:bg-primaryDarkBg">
        <div
          className="w-full md:w-fit p-4 md:p-14 bg-white dark:bg-secondaryDarkBg flex flex-col items-center rounded-3xl"
          style={{ boxShadow: "0px 5px 30px 0px #00000008" }}
        >
          <img
            className="mx-auto h-14 w-14"
            src="/assets/images/netchain-logo.svg"
            alt=""
          />
          <div className="font-bold tracking-wide text-xl md:text-3xl mt-5 mb-2 text-center dark:text-white">
            Recover Your Password
          </div>

          <form
            onKeyDown={(e) => handleEnterSubmit(e, handleForgotPassword)}
            className="w-full"
          >
            <div className="w-full md:w-[30rem] mt-6">
              <label className="form-control ">
                <div className="label">
                  <span className="text-xs md:text-base font-bold tracking-wide dark:text-white">
                    Enter Your Email Address or Username
                  </span>
                </div>
                <input
                  type="email"
                  placeholder="hello@netchainmedia.com"
                  className="p-3 md:p-3 rounded-lg text-sm dark:text-white border border-[#EBF0ED] bg-[#FAFAFA] dark:bg-secondaryDarkBg w-full focus:outline-none focus:border-[#EBF0ED]"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </label>
              <button
                onClick={handleForgotPassword}
                disabled={loading}
                className="bg-usetheme hover:bg-opacity-90 h-12 duration-200 w-full rounded-lg py-3 mt-[1rem] text-white font-bold tracking-wider"
              >
                {!loading ? (
                  "Submit Details"
                ) : (
                  <span className="loading loading-spinner loading-md"></span>
                )}
              </button>
              <div className=" mt-3 flex justify-center items-center gap-1">
                <span className="font-[400] text-sm dark:text-white">
                  Recieved the password?
                </span>
                <Link
                  to="/"
                  className="text-usetheme text-[15px] font-semibold"
                >
                  Login
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
