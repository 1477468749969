import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  clearMessage,
  getCompanyByID,
  updateCompany,
} from "../../store/adminSlice";
import { toast } from "react-toastify";
import BreadCrums from "../../common/BreadCrums";
import ButtonForm from "../buttons/ButtonForm";

export default function UpdateCompanyForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, message, error, statusCode, data } = useSelector(
    (state) => state.admin
  );
  const inputRef = useRef(null);
  const [image, setImage] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    domain: "",
    address: "",
    vat: "",
    inc: "",
    phone: "",
  });

  useEffect(() => {
    if (id) {
      dispatch(getCompanyByID(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (data && data._id === id) {
      setFormData({
        name: data.name || "",
        email: data.email || "",
        domain: data.domain || "",
        address: data.address || "",
        vat: data.vat || "",
        inc: data.inc || "",
        phone: data.phone || "",
      });
    }
  }, [data, id]);

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const { name, email, domain, address, vat, inc, phone } = formData;

    if (!name || !email || !domain || !address || !vat || !inc || !phone) {
      toast.error("All fields are required!");
      return false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      toast.error("Please enter a valid email address!");
      return false;
    }

    // if (image && !["image/png", "image/jpeg", "image/svg+xml"].includes(image.type)) {
    //   toast.error("Only PNG, JPEG or SVG types are allowed!");
    //   return false;
    // }

    // Remove the check for specific allowed types
    // if (!image?.type?.startsWith("image/")) {
    //   toast.error("Only image files are allowed!");
    //   return false;
    // }

    if (image && image.size > 5 * 1024 * 1024) {
      // 5MB
      toast.error("Image size should not exceed 5MB!");
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("domain", formData.domain);
      formDataToSend.append("address", formData.address);
      formDataToSend.append("vat", formData.vat);
      formDataToSend.append("inc", formData.inc);
      formDataToSend.append("phone", formData.phone);
      if (image) formDataToSend.append("logo", image);

      dispatch(updateCompany({ id, data: formDataToSend }));
    }
  };

  useEffect(() => {
    if (statusCode === 200) {
      toast.success(message);
      navigate(-1);
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [loading, message, error, statusCode, dispatch, navigate]);
  return (
    <>
      <div className="">
        <div className="flex justify-between  items-center py-5">
          <BreadCrums
            breadCrum={[
              {
                name: "Admin Dashboard",
                path: "/admin-dashboard/",
              },
              {
                name: "Manage Companies",
                path: "/admin-dashboard/manage-companies",
              },
              {
                name: data.name,
              },
            ]}
          />

          {/* <button
            onClick={handleSubmit}
            disabled={loading}
            className="text-white bg-usetheme text-xs md:text-sm h-12 font-semibold py-[7px] md:py-[10px] px-5 md:px-9 rounded-lg"
          >
            {!loading ? (
              "Update Company"
            ) : (
              <span className="loading loading-spinner loading-md"></span>
            )}
          </button> */}
        </div>
      </div>

      <div className="w-full bg-white dark:bg-secondaryDarkBg rounded-xl p-3 my-4 border-l-4 border-usetheme">
        <h1 className=" text-[#18120F] dark:text-white text-lg md:text-lg font-semibold p-1">
          Update Company
        </h1>
        <form
          className="w-full border border-[#EBF0ED] rounded-lg p-3 uppercase space-y-2"
          onSubmit={handleSubmit}
        >
          {/* Name and email */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Name
              </label>
              <input
                name="name"
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="Netchain"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>

            {/* Email */}
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Email
              </label>
              <input
                name="email"
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="email"
                placeholder="netchain@gmail.com"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Domain and Address */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Domain
              </label>
              <input
                name="domain"
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="www.abc.com"
                value={formData.domain}
                onChange={handleInputChange}
              />
            </div>

            {/* phone */}
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Phone
              </label>
              <input
                name="phone"
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="number"
                placeholder="+923023443234"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Vat and Inc */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Vat
              </label>
              <input
                name="vat"
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder=""
                value={formData.vat}
                onChange={handleInputChange}
              />
            </div>

            {/* Inc */}
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Inc
              </label>
              <input
                name="inc"
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder=""
                value={formData.inc}
                onChange={handleInputChange}
              />
            </div>
          </div>
          {/* Address */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Address
              </label>
              <input
                name="address"
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="Office #408, 4th floor Sheikh Yasin Trade Center Arbab Rd"
                value={formData.address}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Upload Logo */}
          <div>
            <div className="max-w-36">
              <h2 className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold ">
                Upload Logo
              </h2>
              <div
                onClick={handleImageClick}
                className="w-full h-[95%] bg-[#FEFEFE] flex items-center justify-center border border-dashed border-[#E0E0E0] rounded relative"
              >
                {image ? (
                  <img
                    className=""
                    src={URL.createObjectURL(image)}
                    alt="Logo"
                  />
                ) : (
                  <img
                    className="w-16 h-16 p-4"
                    src={data?.logo || "/assets/icons/img-input-2.svg"}
                    alt="Placeholder"
                  />
                )}
                <input
                  ref={inputRef}
                  onChange={handleImageChange}
                  type="file"
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <ButtonForm
              onClick={handleSubmit}
              loading={loading}
              text="Update Company"
            />
          </div>
        </form>
      </div>
    </>
  );
}
