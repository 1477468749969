import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaEnvelope, FaGlobe, FaPhoneAlt } from "react-icons/fa";
import { MdOutlineDateRange } from "react-icons/md";
import {
  clearMessage,
  deleteCompany,
  getCompanies,
  searchCompany,
} from "../../store/adminSlice";
import Loader from "../../common/Loader";
import { Link } from "react-router-dom";
import DeletePopup from "../../common/DeletePopup";
import { toast } from "react-toastify";
import BreadCrums from "../../common/BreadCrums";
import ButtonLink from "../buttons/ButtonLink";
import {
  MdExpandLess,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import Pagination from "../../common/Pagination";
import ActionIconButton from "../buttons/ActionIconButton";
import moment from "moment";

export default function AlllCompaniesTable() {
  const dispatch = useDispatch();
  const { loading, message, error, data, statusCode, appCoreSetting } = useSelector(
    (state) => state.admin
  );

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(appCoreSetting?.coreSettings?.itemsPerPage);
  const [sorting, setSorting] = useState(1);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [expandedRow, setExpandedRow] = useState(null);
  const detailsRef = useRef([]);

  useEffect(() => {
    if (data?.companies?.length >= 0) {
      setExpandedRow(data?.companies[0]?._id);
    }
  }, [data]);
  const toggleRow = (id) => {
    setExpandedRow((prev) => (prev === id ? null : id));
  };

  const getRowHeight = (index) => {
    if (
      detailsRef?.current[index] &&
      expandedRow === data?.companies[index]._id
    ) {
      return `${detailsRef?.current[index].scrollHeight}px`; // Dynamic height based on content
    }
    return "0px";
  };

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);

  const handleDeleteClick = (id) => {
    setSelectedCompanyId(id);
    setShowDeletePopup(true);
  };

  const handleDeleteConfirmation = () => {
    dispatch(deleteCompany(selectedCompanyId)).then(() => {
      dispatch(
        getCompanies({ page: currentPage, limit: itemsPerPage, sort: sorting })
      );
    });
    setShowDeletePopup(false);
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false);
  };

  const handleSearch = (e) => {
    const query = e.target.value.trim();
    setSearchQuery(query);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (e.key === "Enter") {
      if (query === "") {
        dispatch(
          getCompanies({
            page: currentPage,
            limit: itemsPerPage,
            sort: sorting,
          })
        );
      } else {
        dispatch(searchCompany(query));
      }
    } else {
      const timeout = setTimeout(() => {
        if (query === "") {
          dispatch(
            getCompanies({
              page: currentPage,
              limit: itemsPerPage,
              sort: sorting,
            })
          );
        } else {
          dispatch(searchCompany(query));
        }
      }, 1000);

      setSearchTimeout(timeout);
    }
  };

  useEffect(() => {
    dispatch(
      getCompanies({ page: currentPage, limit: itemsPerPage, sort: sorting })
    );
  }, [currentPage, itemsPerPage]);

  const totalPages = data?.pagination?.totalPages || 0;

  // pagination changes
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const handlePageSelectChange = (event) => {
    const selectedPage = parseInt(event.target.value, 10);
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    if (statusCode === 200) {
      toast.success(message);
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [loading, message, error]);

  return (
    <>
      <div className="">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center py-5 space-y-4 sm:space-y-0">
          <BreadCrums
            breadCrum={[
              {
                name: "Admin Dashboard",
                path: "/admin-dashboard/",
              },
              {
                name: "Companies",
                path: "",
              },
            ]}
          />
          <ButtonLink to="add-company" text="Add New Company" />
        </div>
        <div className="relative flex items-center w-full">
          <img
            src="/assets/icons/search.svg"
            alt=""
            className="absolute left-3 text-[#C19A6B]"
          />
          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white dark:bg-secondaryDarkBg border border-[#EBF0ED] focus:outline-none text-[#6B6B6B]  font-[500] text-[14px]"
            type="search"
            placeholder="Search Companies"
            onChange={handleSearch}
            onKeyDown={handleSearch}
          />
        </div>
      </div>
      <div className="py-4">
        {loading ? (
          <div className="flex justify-center py-2">
            <Loader />
          </div>
        ) : data?.companies?.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {data?.companies?.map((item) => (
              <div
                key={item._id}
                className="bg-gradient-to-br from-white to-gray-50 dark:from-secondaryDarkBg dark:to-gray-800 shadow-lg rounded-lg overflow-hidden hover:shadow-2xl transition-all duration-300 ease-in-out"
              >
                {/* Logo Section */}
                <div className="relative p-6 bg-gray-100 dark:bg-gray-700 flex items-center justify-center border-b border-gray-200 dark:border-gray-600">
                  <div className="relative w-full flex items-center justify-center">
                    <img
                      className="object-contain h-16 lg:h-20"
                      src={item.logo || "/assets/images/alt-logo.png"}
                      alt={`${item.name} Logo`}
                    />
                  </div>
                </div>

                {/* Content Section */}
                <div className="p-6">
                  {/* Company Details */}
                  <div className="mb-4">
                    <h3 className="text-2xl font-bold text-gray-800 dark:text-white capitalize">
                      {item.name}
                    </h3>
                    <p className="text-sm text-gray-500 dark:text-gray-300">
                      {item.address}
                    </p>
                  </div>

                  {/* Contact Information */}
                  <div className="space-y-3 text-sm">
                    <div className="flex items-center text-gray-600 dark:text-gray-300">
                      <FaEnvelope className="mr-2 text-usetheme" />
                      <a
                        href={`mailto:${item.email}`}
                        className="hover:underline truncate"
                      >
                        {item.email}
                      </a>
                    </div>
                    <div className="flex items-center text-gray-600 dark:text-gray-300">
                      <FaPhoneAlt className="mr-2 text-usetheme" />
                      <a
                        href={`tel:${item.phone}`}
                        className="hover:underline truncate"
                      >
                        {item.phone.startsWith("+")
                          ? item.phone
                          : `+${item.phone}`}
                      </a>
                    </div>
                    <div className="flex items-center text-gray-600 dark:text-gray-300">
                      <FaGlobe className="mr-2 text-usetheme" />
                      <a
                        href={item.domain}
                        target="_blank"
                        rel="noreferrer"
                        className="hover:underline truncate"
                      >
                        {item.domain}
                      </a>
                    </div>
                  </div>

                  {/* Registration Info */}
                  <div className="mt-4 text-sm text-gray-500 dark:text-gray-400">
                    <p>
                      <span className="font-medium text-gray-700 dark:text-gray-300">
                        INC:
                      </span>{" "}
                      {item.inc}
                    </p>
                    <p>
                      <span className="font-medium text-gray-700 dark:text-gray-300">
                        VAT:
                      </span>{" "}
                      {item.vat}
                    </p>
                    <p>
                      <span className="font-medium text-gray-700 dark:text-gray-300">
                        Registered:
                      </span>{" "}
                      {moment(item.createdAt).format("DD MMMM YYYY")}
                    </p>
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="p-6 bg-gray-100 dark:bg-gray-700 flex justify-between items-center border-t">
                  <a
                    href={`https://wa.me/${item.phone}`}
                    target="_blank"
                    rel="noreferrer"
                    className="px-5 py-2 bg-usetheme text-white text-sm font-semibold rounded-lg shadow-md hover:shadow-lg hover:bg-opacity-90 transition"
                  >
                    Contact
                  </a>
                  <div className="flex items-center space-x-3">
                    <ActionIconButton
                      to={`update-company/${item._id}`}
                      iconPath="/assets/icons/edit-icon.svg"
                      alt="edit"
                    />
                    <ActionIconButton
                      onClick={() => handleDeleteClick(item._id)}
                      iconPath="/assets/icons/delete-icon.svg"
                      alt="delete"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center font-bold dark:text-white tracking-wide py-2">
            No Companies Found
          </div>
        )}
      </div>

      {/* <div className="my-5">
        <div className="hidden lg:block overflow-x-auto lg:max-w-[56rem] xl:min-w-full">
          <table className="min-w-full bg-white dark:bg-secondaryDarkBg rounded-lg shadow-lg overflow-hidden">
            <thead>
              <tr className="bg-usetheme text-white">
                <th className="py-3 px-2 md:px-6 text-left text-xs md:text-sm font-medium tracking-wider">
                  Logo
                </th>
                <th className="py-3 px-2 md:px-6 text-left text-xs md:text-sm font-medium tracking-wider">
                  Name
                </th>
                <th className="py-3 px-2 md:px-6 text-left text-xs md:text-sm font-medium tracking-wider">
                  Email
                </th>
                <th className="py-3 px-2 md:px-6 text-left text-xs md:text-sm font-medium tracking-wider">
                  Domain
                </th>
                <th className="py-3 px-2 md:px-6 text-left text-xs md:text-sm font-medium tracking-wider">
                  INC
                </th>
                <th className="py-3 px-2 md:px-6 text-left text-xs md:text-sm font-medium tracking-wider">
                  VAT
                </th>
                <th className="py-3 px-2 md:px-6 text-left text-xs md:text-sm font-medium tracking-wider">
                  Address
                </th>
                <th className="py-3 px-2 md:px-6 text-left text-xs md:text-sm font-medium tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {loading ? (
                <tr>
                  <td colSpan="10">
                    <div className="flex justify-center py-2">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : data?.companies?.length > 0 ? (
                data?.companies?.map((com) => (
                  <tr className="hover:bg-gray-100 dark:hover:bg-secondaryDarkBg">
                    <td className="py-4 px-2 md:px-6 text-sm text-gray-700 dark:text-white">
                      <img
                        className="h-8 w-8 md:h-10 md:w-10 rounded-full "
                        src={com.logo || "/assets/images/alt-logo.png"}
                        alt=""
                        onError={(e) => {
                          e.target.onerror = null; // Prevents looping in case the fallback image also fails to load
                          e.target.src = "/assets/images/alt-logo.png"; // Path to your alternative image
                        }}
                      />
                    </td>
                    <td className="py-4 px-2 md:px-6 text-xs md:text-sm text-gray-700 dark:text-white break-all">
                      {com.name}
                    </td>
                    <td className="py-4 px-2 md:px-6 text-xs md:text-sm text-gray-700 dark:text-white break-all">
                      {com.email}
                    </td>
                    <td className="py-4 px-2 md:px-6 text-xs md:text-sm">
                      <a
                        href={com.domain}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline break-all"
                      >
                        {com.domain}
                      </a>
                    </td>
                    <td className="py-4 px-2 md:px-6 text-xs md:text-sm text-gray-700 dark:text-white break-all">
                      {com.inc}
                    </td>
                    <td className="py-4 px-2 md:px-6 text-xs md:text-sm text-gray-700 dark:text-white break-all">
                      {com.vat}
                    </td>
                    <td className="py-4 px-2 md:px-6 text-xs md:text-sm text-gray-700 dark:text-white break-all">
                      {com.address}
                    </td>
                    <td className="mt-4 px-2 md:px-3 flex gap-2 items-center">
                      <Link className="" to={`update-company/${com._id}`}>
                        <img
                          className="max-w-8 max-h-8 md:max-w-16 md:max-h-16"
                          src="/assets/icons/edit.svg"
                          alt="Edit"
                        />
                      </Link>
                      <Link to={`view-company/${com._id}`}>
                        <img
                          className="max-w-8 max-h-8 md:max-w-16 md:max-h-16"
                          src="/assets/icons/view.svg"
                          alt="View"
                        />
                      </Link>
                      <Link onClick={() => handleDeleteClick(com._id)}>
                        <img
                          className="max-w-8 max-h-8 md:max-w-16 md:max-h-16"
                          src="/assets/icons/delete.svg"
                          alt="Delete"
                        />
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10">
                    <div className="text-center font-bold dark:text-white tracking-wide py-2">
                      No Companies Found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="lg:hidden">
          {loading ? (
            <div className="flex justify-center items-center py-2">
              <Loader />
            </div>
          ) : data?.companies?.length > 0 ? (
            data.companies.map((com, index) => (
              <div key={com._id} className="mt-4">
                <div
                  onClick={() => toggleRow(com._id)}
                  className={`${
                    expandedRow === com._id
                      ? "rounded-tl-xl rounded-tr-xl"
                      : "rounded-xl"
                  } cursor-pointer flex justify-between items-center px-4 py-2 bg-usetheme text-white text-sm `}
                >
                  <div className="font-semibold">Name</div>
                  <div>{com.name}</div>
                  <div>{expandedRow === com._id ? (<MdExpandLess size={24} />) : (<MdExpandLess className="rotate-180" size={24} />)}</div>
                </div>

                <div
                  ref={(el) => (detailsRef.current[index] = el)}
                  style={{
                    maxHeight: getRowHeight(index),
                    transition:
                      "max-height 0.3s ease-out, opacity 0.3s ease-out",
                    overflow: "hidden",
                    opacity: expandedRow === com._id ? 1 : 0,
                  }}
                >
                  <div className="flex justify-between items-center bg-white dark:bg-secondaryDarkBg px-4 py-2 text-xxs border-b">
                    <div className="font-semibold dark:text-white">Logo</div>
                    <img
                      className="h-8 w-8 md:h-10 md:w-10 rounded-full"
                      src={com.logo || "/assets/images/alt-logo.png"}
                      alt=""
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "/assets/images/alt-logo.png";
                      }}
                    />
                  </div>
                  <div className="flex justify-between bg-white dark:bg-secondaryDarkBg px-4 py-2 text-xxs border-b">
                    <div className="font-semibold dark:text-white">Email</div>
                    <div className="dark:text-white">{com.email}</div>
                  </div>
                  <div className="flex justify-between bg-white dark:bg-secondaryDarkBg px-4 py-2 text-xxs border-b">
                    <div className="font-semibold dark:text-white">Domain</div>
                    <div>
                      <a
                        href={com.domain}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        {com.domain}
                      </a>
                    </div>
                  </div>
                  <div className="flex justify-between bg-white dark:bg-secondaryDarkBg px-4 py-2 text-xxs border-b">
                    <div className="font-semibold dark:text-white">INC</div>
                    <div className="dark:text-white">{com.inc}</div>
                  </div>
                  <div className="flex justify-between bg-white dark:bg-secondaryDarkBg px-4 py-2 text-xxs border-b">
                    <div className="font-semibold dark:text-white">VAT</div>
                    <div className="dark:text-white">{com.vat}</div>
                  </div>
                  <div className="flex justify-between bg-white dark:bg-secondaryDarkBg px-4 py-2 text-xxs border-b">
                    <div className="font-semibold dark:text-white">Address</div>
                    <div className="dark:text-white">{com.address}</div>
                  </div>
                  <div className="rounded-bl-xl rounded-br-xl flex justify-between items-center bg-white dark:bg-secondaryDarkBg px-4 py-2 text-xxs">
                    <div className="font-semibold dark:text-white">Actions</div>
                    <div className="flex gap-2">
                      <Link to={`update-company/${com._id}`}>
                        <img
                          className="h-8 w-8"
                          src="/assets/icons/edit.svg"
                          alt="Edit"
                        />
                      </Link>
                      <Link to={`view-company/${com._id}`}>
                        <img
                          className="h-8 w-8"
                          src="/assets/icons/view.svg"
                          alt="View"
                        />
                      </Link>
                      <Link onClick={() => handleDeleteClick(com._id)}>
                        <img
                          className="h-8 w-8"
                          src="/assets/icons/delete.svg"
                          alt="Delete"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center font-bold tracking-wide py-2">
              No Companies Found
            </div>
          )}
        </div>
      </div> */}

      {/* Pagination Controls */}
      {!loading && data?.companies?.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          handlePageSelectChange={handlePageSelectChange}
        />
      )}

      {showDeletePopup && (
        <DeletePopup
          isVisible={showDeletePopup}
          onDeleteConfirmation={handleDeleteConfirmation}
          onDeleteCancel={handleDeleteCancel}
        />
      )}
    </>
  );
}
