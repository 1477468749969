import React, { useEffect, useState } from "react";
import ServiceCard from "./ServiceCard";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, getAllServicesHome } from "../../store/adminSlice";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";
import Pagination from "../../common/Pagination";

export default function ServicesManagement() {
  const dispatch = useDispatch();
  const { loading, message, error, data, statusCode, appCoreSetting } = useSelector(
    (state) => state.admin
  );

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(appCoreSetting?.coreSettings?.itemsPerPage);
  const [sorting, setSorting] = useState(1);

  const [services, setServices] = useState([]);

  useEffect(() => {
    if (data?.services) {
      setServices(data.services);
    }
  }, [data?.services]);

  useEffect(() => {
    dispatch(
      getAllServicesHome({ page: currentPage, limit: itemsPerPage, sort: sorting })
    );
  }, [dispatch]);

  const handleServiceDeleted = (deletedServiceId) => {
    setServices((prevServices) =>
      prevServices.filter((service) => service._id !== deletedServiceId)
    );
  };

  useEffect(() => {
    dispatch(
      getAllServicesHome({ page: currentPage, limit: itemsPerPage, sort: sorting })
    );
  }, [currentPage, itemsPerPage]);

  const totalPages = data?.pagination?.totalPages || 0;

  // pagination changes
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const handlePageSelectChange = (event) => {
    const selectedPage = parseInt(event.target.value, 10);
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    if (statusCode === 200 && message) {
      toast.success(message);
    } else if (error) {
      toast.error(error);
    }

    dispatch(clearMessage());
  }, [statusCode, message, error, dispatch]);

  return (
    <>
      <div className="py-4">
        {loading ? (
          <div className="flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {services.length > 0 ? (
              services.map((item) => (
                <ServiceCard
                  key={item._id}
                  item={item}
                  onServiceDeleted={handleServiceDeleted}
                />
              ))
            ) : (
              <p className="col-span-full text-center text-gray-600">
                No Services Found
              </p>
            )}
          </div>
        )}
      </div>

      {/* Pagination Controls */}
      {!loading && data?.services?.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          handlePageSelectChange={handlePageSelectChange}
        />
      )}
    </>
  );
}
