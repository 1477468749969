import React, { useEffect, useState } from "react";
import { FaDownload } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  getCompanyByID,
  getInvoiceByID,
  getUserByUsername,
} from "../../store/adminSlice";
import html2pdf from "html2pdf.js";
import QRCode from "react-qr-code";
import BreadCrums from "../../common/BreadCrums";
import Loader from "../../common/Loader";
import moment from "moment";

export default function InvoiceTemplate() {
  const { id } = useParams();

  const [companyDataLoading, setCompanyDataLoading] = useState(true)

  const dispatch = useDispatch();
  const {
    loading,
    error,
    invoiceByIdData,
    companyByIdData,
    userByUserNameData,
  } = useSelector((state) => state.admin);

  const [base64Logo, setBase64Logo] = useState(null);

  useEffect(() => {
    dispatch(getInvoiceByID(id));
  }, []);

  useEffect(() => {
    if (invoiceByIdData?.company) {
      dispatch(getCompanyByID(invoiceByIdData?.company))
    }
  }, [dispatch, invoiceByIdData?.company]);

  const [isRoundLogo, setIsRoundLogo] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = companyByIdData?.logo;
    img.onload = () => {
      const isRound = img.width === img.height;
      setIsRoundLogo(isRound);
    };
  }, [companyByIdData?.logo]);

  useEffect(() => {
    if (invoiceByIdData?.to) {
      dispatch(getUserByUsername(invoiceByIdData?.to));
    }
  }, [dispatch, invoiceByIdData?.to]);

  useEffect(() => {
    const convertImageToBase64 = async () => {
      try {
        console.log("Fetching image from URL:", companyByIdData?.logo);
  
        const img = new Image();
        img.crossOrigin = 'Anonymous'; 
  
        
        img.onload = () => {
          
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
  
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
  
          
          const base64 = canvas.toDataURL('image/png');
          setBase64Logo(base64); 
          console.log("Base64 Image:", base64);
          setCompanyDataLoading(false)
        };
        img.crossOrigin = 'Anonymous'; 
        img.src = companyByIdData?.logo; 
  
      } catch (error) {
        console.error("Error converting image to Base64:", error);
      }
    };
  
    if (companyByIdData) {
      convertImageToBase64();
    }
  }, [companyByIdData]);
  

  const handleDownload = () => {
    const mobileInvoice = document.getElementById("invoice");
    const desktopInvoice = document.getElementById("desktop-invoice");
    const loadingOverlay = document.getElementById("loading-overlay");

    if (!desktopInvoice || !loadingOverlay) {
      console.error("Desktop invoice or loading overlay not found");
      return;
    }

    
    loadingOverlay.classList.remove("hidden");
    loadingOverlay.classList.add("flex");

   
    mobileInvoice.classList.add("hidden");
    desktopInvoice.classList.remove("hidden");

    const options = {
      margin: 0,
      filename: `invoice-${invoiceByIdData?.number}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf()
      .from(desktopInvoice) 
      .set(options)
      .save()
      .finally(() => {
        
        loadingOverlay.classList.add("hidden");
        loadingOverlay.classList.remove("flex");

        
        desktopInvoice.classList.add("hidden");
        mobileInvoice.classList.remove("hidden");
      });
  };

  const localUrl = "http://localhost:3000/";
  const liveUrl = "https://office.netchainmedia.com/";
  const qrValue = `${liveUrl}view/${invoiceByIdData?.number}/${invoiceByIdData?.secret}`;

  return (
    <>
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center py-5 space-y-4 sm:space-y-0">
        <BreadCrums
          breadCrum={[
            { name: "Admin Dashboard", path: "/admin-dashboard/" },
            {
              name: "Manage Invoices",
              path: "/admin-dashboard/manage-invoices",
            },
            { name: "View Invoice" },
          ]}
        />

        {/* Download Button */}
        {companyDataLoading ? (
          <></>
        ) : (
          <>
            {" "}
            <div className="flex justify-center">
              <button
                onClick={handleDownload}
                className="flex items-center text-white bg-usetheme hover:bg-usetheme_hover py-1 px-2 md:py-2 md:px-4 rounded text-xxs md:text-base"
              >
                <FaDownload className="mr-2" /> Download Invoice
              </button>
            </div>
          </>
        )}
      </div>
      {companyDataLoading || loading ? (
        <>
          <div className="flex justify-center py-4">
            <Loader />
          </div>
        </>
      ) : (
        <>
          <div
            id="invoice"
            className="max-w-4xl mx-auto p-8 mb-4 bg-white border-gray-200 rounded-lg print:bg-white print:border-none print:shadow-none"
          >
            {/* Company Header */}
            
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6 gap-4  border-b pb-4">
              <div className={`flex ${isRoundLogo ? "flex-row" : "flex-col"}`}>
                <img
                  src={base64Logo}
                  alt="Company Logo"
                  className={`object-contain ${
                    isRoundLogo
                      ? "w-10 h-10 md:w-20 md:h-20"
                      : "w-16 h-8 md:w-44 md:h-16"
                  } ${isRoundLogo ? "rounded-full" : ""}`}
                />
                <div className={`${isRoundLogo ? "ml-4" : ""}`}>
                  <h1 className="text-sm md:text-xl font-light text-gray-800">
                    {companyByIdData?.name}
                  </h1>
                  <p className="text-xxs md:text-sm text-gray-500">
                    {companyByIdData?.address}
                  </p>
                  {companyByIdData?.email && (
                    <p className="text-xxs md:text-sm text-gray-500">
                      Email: {companyByIdData?.email}
                    </p>
                  )}
                </div>
              </div>
              <div className="w-full md:w-[23%]">
                {companyByIdData?.phone && (
                  <p className="text-xxs md:text-sm text-gray-500">
                    Phone: {companyByIdData?.phone}
                  </p>
                )}
                {companyByIdData?.vat && (
                  <p className="text-xxs md:text-sm text-gray-500">
                    VAT #: {companyByIdData?.vat}
                  </p>
                )}
                {companyByIdData?.inc && (
                  <p className="text-xxs md:text-sm text-gray-500">
                    Company #: {companyByIdData?.inc}
                  </p>
                )}
                {companyByIdData?.domain && (
                  <p className="text-xxs md:text-sm text-gray-500">
                    {companyByIdData?.domain}
                  </p>
                )}
              </div>
            </div>

            {/* Invoice Info */}
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-6 ">
              <div>
                <h2 className="text-xs md:text-sm font-semibold text-gray-700">
                  Invoice # {invoiceByIdData?.number}
                </h2>
                <p className="text-xxs md:text-sm text-gray-500">
                  Invoice Date:{" "}
                  {moment(invoiceByIdData?.date).format("D MMMM YYYY")}
                </p>
                <p className="text-xxs md:text-sm text-gray-500">
                  Payment Month: {invoiceByIdData?.paymentMonth}
                </p>
              </div>
              <div className="text-left w-full md:w-[23%]">
                <h3 className="text-xs md:text-sm font-semibold text-gray-700">
                  Invoice To
                </h3>
                <p className=" text-xxs md:text-sm text-gray-500">
                  Name: {userByUserNameData?.firstName}{" "}
                  {userByUserNameData?.lastName}
                </p>
                <p className="text-xxs md:text-sm text-gray-500">
                  Address: {userByUserNameData?.address}
                </p>
                <p className="text-xxs md:text-sm text-gray-500">
                  Account: {userByUserNameData?.account}
                </p>
              </div>
            </div>

            {/* Task List */}
            <div className="overflow-x-auto mb-6">
              <table className="min-w-full table-auto text-left">
                <thead className="hidden md:table-header-group">
                  <tr>
                    <th className="px-4 py-2 text-sm font-medium text-gray-700">
                      #
                    </th>
                    <th className="px-4 py-2 text-sm font-medium text-gray-700">
                      Task
                    </th>
                    <th className="px-4 py-2 text-sm font-medium text-gray-700">
                      Details
                    </th>
                    <th className="px-4 py-2 text-sm font-medium text-gray-700">
                      Hours Logged
                    </th>
                  </tr>
                </thead>
                <tbody className="md:table-row-group">
                  {invoiceByIdData?.items?.map((item, index) => (
                    <tr
                      key={index}
                      className="border-b md:table-row block md:border-none"
                    >
                      {/* Mobile view format */}
                      <div className="flex flex-col mb-4 md:hidden border rounded-xl">
                        <div className=" flex justify-between rounded-tl-xl rounded-tr-xl px-4 py-2 text-xxs md:text-sm font-medium bg-usetheme text-white">
                          <span>#</span>
                          <span>{index + 1}</span>
                        </div>
                        <div className="border-b flex justify-between px-4 py-2 text-xxs md:text-sm font-medium text-gray-700 gap-4">
                          <span>Task</span>
                          <span>{item.task}</span>
                        </div>
                        <div className="border-b flex justify-between px-4 py-2 text-xxs md:text-sm font-medium text-gray-700 gap-4">
                          <span>Details</span>
                          <span className="text-wrap">{item.detail}</span>
                        </div>
                        <div className="flex justify-between px-4 py-2 text-xxs md:text-sm font-medium text-gray-700">
                          <span>Hours Logged</span>
                          <span>{item.log} hrs</span>
                        </div>
                      </div>

                      {/* Desktop view format */}
                      <td className="hidden md:table-cell px-4 py-2 text-sm text-gray-600">
                        {index + 1}
                      </td>
                      <td className="hidden md:table-cell px-4 py-2 text-sm text-gray-600">
                        {item.task}
                      </td>
                      <td className="hidden md:table-cell px-4 py-2 text-sm text-gray-600">
                        {item.detail}
                      </td>
                      <td className="hidden md:table-cell px-4 py-2 text-sm text-gray-600">
                        {item.log} hrs
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Invoice Summary */}
            <div className="flex justify-end mb-6">
              <div className="w-full md:w-1/2 lg:w-1/3">
                <div className="flex justify-between items-center py-1">
                  <p className="text-xxs md:text-sm text-gray-600">Bonus</p>
                  <p className="text-xxs md:text-sm  text-gray-800">
                    ${invoiceByIdData?.bonus}
                  </p>
                </div>
                <div className="flex justify-between items-center py-1">
                  <p className="text-xxs md:text-sm text-gray-600">Basic Pay</p>
                  <p className="text-xxs md:text-sm  text-gray-800">
                    $
                    {/* {Number(invoiceByIdData?.total || 0) -
                      Number(invoiceByIdData?.bonus || 0)} */}
                    {Number(invoiceByIdData?.basicPay || 0)}
                  </p>
                </div>
                <div className="flex justify-between items-center py-1">
                  <p className="text-xxs md:text-sm font-semibold text-gray-800">
                    Net Total
                  </p>
                  <p className="text-xxs md:text-sm font-semibold text-gray-800">
                    ${" "}
                    {Number(invoiceByIdData?.basicPay || 0) +
                      Number(invoiceByIdData?.bonus || 0)}
                  </p>
                </div>
                <div className="flex justify-between items-center py-1">
                  <p className="text-xxs md:text-sm text-gray-600">Charges</p>
                  <p className="text-xxs md:text-sm  text-gray-800">
                    ${invoiceByIdData?.charges}
                  </p>
                </div>
                <div className="flex justify-between items-center py-1">
                  <p className="text-xxs md:text-sm font-semibold text-gray-800">
                    Gross Total
                  </p>
                  <p className="text-xxs md:text-sm font-semibold text-gray-800">
                    $
                    {Number(invoiceByIdData?.basicPay || 0) +
                      (invoiceByIdData?.charges || 0) + (invoiceByIdData?.bonus || 0)}
                  </p>
                </div>
              </div>
            </div>

            {/* Footer */}
            <div className="flex flex-col justify-center items-center border-t pt-6">
              {/* QR Code */}
              <div className="">
                <QRCode size={80} value={qrValue} />
              </div>
              <div className="text-center pt-4 text-xs md:text-sm text-gray-600 print:border-none">
                <p>Thank you for your business!</p>
                <p className="text-xxs md:text-xs">
                  This self-generated payment confirmation, issued by the{" "}
                  <span className="uppercase">{companyByIdData?.name}</span>,
                  does not require a signature. You can verify it online at{" "}
                  <Link
                    to={`${liveUrl}view/${invoiceByIdData?.number}/${invoiceByIdData?.secret}`}
                  >
                    {companyByIdData?.domain}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Loading Overlay */}
      <div
        id="loading-overlay"
        className="fixed inset-0 bg-gray-900 bg-opacity-75 justify-center items-center text-white hidden z-50"
      >
        <p className="text-xs md:text-xl">Please wait, generating PDF...</p>
      </div>

      {/* for the downlaod button */}
      <div
        id="desktop-invoice"
        className="hidden max-w-4xl mx-auto p-8 bg-white border-gray-200 rounded-lg print:bg-white print:border-none print:shadow-none"
      >
        {/* Company Header */}
        <div className="flex justify-between items-center mb-6 border-b pb-4">
          <div className={`flex ${isRoundLogo ? "flex-row" : "flex-col"}`}>
            <img
              src={base64Logo}
              alt="Company Logo"
              className={`object-contain ${
                isRoundLogo ? "w-20 h-20" : "max-w-64 h-10"
              } ${isRoundLogo ? "rounded-full" : ""}`}
            />
            <div className={`${isRoundLogo ? "ml-4" : ""}`}>
              <h1 className="text-xl font-light text-gray-800">
                {companyByIdData?.name}
              </h1>
              <p className="text-sm text-gray-500">
                {companyByIdData?.address}
              </p>
              {companyByIdData?.email && (
                <p className="text-sm text-gray-500">
                  Email: {companyByIdData?.email}
                </p>
              )}
            </div>
          </div>
          <div className="w-[23%]">
            {companyByIdData?.phone && (
              <p className="text-sm text-gray-500">
                Phone: {companyByIdData?.phone}
              </p>
            )}
            {companyByIdData?.vat && (
              <p className="text-sm text-gray-500">
                VAT #: {companyByIdData?.vat}
              </p>
            )}
            {companyByIdData?.inc && (
              <p className="text-sm text-gray-500">
                Company #: {companyByIdData?.inc}
              </p>
            )}
            {companyByIdData?.domain && (
              <p className="text-sm text-gray-500">{companyByIdData?.domain}</p>
            )}
          </div>
        </div>

        {/* Invoice Info */}
        <div className="flex justify-between items-center gap-4 mb-6 ">
          <div>
            <h2 className="text-lg font-semibold text-gray-700">
              Invoice # {invoiceByIdData?.number}
            </h2>
            <p className="text-sm text-gray-500">
              Invoice Date:{" "}
              {moment(invoiceByIdData?.date).format("D MMMM YYYY")}
            </p>
            <p className="text-sm text-gray-500">
              Payment Month: {invoiceByIdData?.paymentMonth}
            </p>
          </div>
          <div className="text-left w-[23%]">
            <h3 className="text-sm font-semibold text-gray-700">Invoice To</h3>
            <p className="text-sm text-gray-500">
              Name: {userByUserNameData?.firstName}{" "}
              {userByUserNameData?.lastName}
            </p>
            <p className="text-sm text-gray-500">
              Address: {userByUserNameData?.address}
            </p>
            <p className="text-sm text-gray-500">
              Account: {userByUserNameData?.account}
            </p>
          </div>
        </div>

        {/* Task List */}
        <div className="overflow-hidden mb-6">
          <table className="min-w-full table-auto text-left">
            <thead className="">
              <tr>
                <th className="px-4 py-2 text-sm font-medium text-gray-700">
                  #
                </th>
                {/* <th className="px-4 py-2 text-sm font-medium text-gray-700">
                      Task Number
                    </th> */}
                <th className="px-4 py-2 text-sm font-medium text-gray-700">
                  Task
                </th>
                <th className="px-4 py-2 text-sm font-medium text-gray-700">
                  Details
                </th>
                <th className="px-4 py-2 text-sm font-medium text-gray-700">
                  Hours Logged
                </th>
              </tr>
            </thead>
            <tbody>
              {invoiceByIdData?.items?.map((item, index) => (
                <tr key={index} className="border-b print:border-b">
                  <td className="px-4 py-2 text-sm text-gray-600">
                    {index + 1}
                  </td>
                  {/* <td className="px-4 py-2 text-sm text-gray-600">
                        {item.taskNumber}
                      </td> */}
                  <td className="px-4 py-2 text-sm text-gray-600">
                    {item.task}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-600">
                    {item.detail}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-600">
                    {item.log} hrs
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Invoice Summary */}
        <div className="flex justify-end mb-6">
          <div className="w-full md:w-1/2 lg:w-1/3">
            <div className="flex justify-between items-center py-1">
              <p className="text-sm text-gray-600">Bonus</p>
              <p className="text-sm  text-gray-800">
                ${invoiceByIdData?.bonus}
              </p>
            </div>
            <div className="flex justify-between items-center py-1">
              <p className="text-sm text-gray-600">Basic Pay</p>
              <p className="text-sm  text-gray-800">
              ${invoiceByIdData?.basicPay}
                {/* {Number(invoiceByIdData?.total || 0) -
                  Number(invoiceByIdData?.bonus || 0)} */}
              </p>
            </div>
            <div className="flex justify-between items-center py-1">
              <p className="text-sm font-semibold text-gray-800">Net Total</p>
              <p className="text-sm font-semibold text-gray-800">
                ${Number(invoiceByIdData?.basicPay || 0) + (invoiceByIdData?.bonus || 0)}
                {/* ${invoiceByIdData?.total} */}
              </p>
            </div>
            <div className="flex justify-between items-center py-1">
              <p className="text-sm text-gray-600">Charges</p>
              <p className="text-sm  text-gray-800">
                ${invoiceByIdData?.charges}
              </p>
            </div>
            <div className="flex justify-between items-center py-1">
              <p className="text-sm font-semibold text-gray-800">Gross Total</p>
              <p className="text-sm font-semibold text-gray-800">
                $
                {Number(invoiceByIdData?.basicPay || 0) + (invoiceByIdData?.bonus || 0)
                 + (invoiceByIdData?.charges || 0)}
              </p>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="flex flex-col justify-center items-center border-t pt-6">
          {/* QR Code */}
          <div className="">
            <QRCode size={80} value={qrValue} />
          </div>
          <div className="text-center pt-4 text-sm text-gray-600 print:border-none">
            <p>Thank you for your business!</p>
            <p className="text-xs">
              This self-generated payment confirmation, issued by the{" "}
              <span className="uppercase">{companyByIdData?.name}</span>, does
              not require a signature. You can verify it online at{" "}
              <Link
                to={`${liveUrl}view/${invoiceByIdData?.number}/${invoiceByIdData?.secret}`}
              >
                {companyByIdData?.domain}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
