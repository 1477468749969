import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyByIDPublic,
  getInvoiceByIDPublic,
  getUserByUsername,
} from "../../store/adminSlice";
import { Link, useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import QRCode from "react-qr-code";
import Loader from "../../common/Loader";
import moment from "moment";

export default function PublicInvoiceViewTemplate() {
  const { number, secret } = useParams();

  const dispatch = useDispatch();
  const {
    loading,
    error,
    invoiceByIdDataPublic,
    companyByIdDataPublic,
    userByUserNameData,
  } = useSelector((state) => state.admin);

  // const [base64Logo, setBase64Logo] = useState(null);

  useEffect(() => {
    dispatch(getInvoiceByIDPublic({ number: number, secret: secret }));
  }, [dispatch, secret, number]);

  useEffect(() => {
    if (invoiceByIdDataPublic?.company) {
      dispatch(getCompanyByIDPublic(invoiceByIdDataPublic?.company));
    }
  }, [dispatch, invoiceByIdDataPublic?.company]);

  useEffect(() => {
    if (invoiceByIdDataPublic?.to) {
      dispatch(getUserByUsername(invoiceByIdDataPublic?.to));
    }
  }, [dispatch, invoiceByIdDataPublic?.to]);

  const [isRoundLogo, setIsRoundLogo] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = companyByIdDataPublic?.logo;
    img.onload = () => {
      const isRound = img.width === img.height;
      setIsRoundLogo(isRound);
    };
  }, [companyByIdDataPublic?.logo]);

  // useEffect(() => {
  //   if (companyByIdDataPublic?.logo) {
  //     fetch(companyByIdDataPublic.logo)
  //       .then((response) => response.blob())
  //       .then((blob) => {
  //         const reader = new FileReader();
  //         reader.onloadend = () => {
  //           setBase64Logo(reader.result);
  //         };
  //         reader.readAsDataURL(blob);
  //       })
  //       .catch((error) =>
  //         console.error("Error converting image to Base64:", error)
  //       );
  //   }
  // }, [companyByIdDataPublic?.logo]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <Loader />
      </div>
    );
  }

  const localUrl = "http://localhost:3000/";
  const liveUrl = "https://office.netchainmedia.com/";
  const qrValue = `${liveUrl}view/${invoiceByIdDataPublic?.number}/${invoiceByIdDataPublic?.secret}`;
  return (
    <>
      <div
        id="invoice"
        className="max-w-4xl mx-auto p-8 bg-[#faf9f9] border-gray-200 rounded-lg print:bg-white print:border-none print:shadow-none my-4"
      >
        {/* Company Header */}
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6 gap-4  border-b pb-4">
          <div className={`flex ${isRoundLogo ? "flex-row" : "flex-col"}`}>
            <img
              src={companyByIdDataPublic?.logo}
              alt="Company Logo"
              className={`object-contain ${
                isRoundLogo
                  ? "w-10 h-10 md:w-20 md:h-20"
                  : "w-16 h-8 md:w-32 md:h-16"
              } ${isRoundLogo ? "rounded-full" : ""}`}
            />
            <div className={`${isRoundLogo ? "ml-4" : ""}`}>
              <h1 className="text-sm md:text-xl font-light text-gray-800">
                {companyByIdDataPublic?.name}
              </h1>
              <p className="text-xxs md:text-sm text-gray-500">
                {companyByIdDataPublic?.address}
              </p>
              {companyByIdDataPublic?.email && (
                <p className="text-xxs md:text-sm text-gray-500">
                  Email: {companyByIdDataPublic?.email}
                </p>
              )}
            </div>
          </div>
          <div className="w-full md:w-[23%]">
            {companyByIdDataPublic?.phone && (
              <p className="text-xxs md:text-sm text-gray-500">
                Phone: {companyByIdDataPublic?.phone}
              </p>
            )}
            {companyByIdDataPublic?.vat && (
              <p className="text-xxs md:text-sm text-gray-500">
                VAT #: {companyByIdDataPublic?.vat}
              </p>
            )}
            {companyByIdDataPublic?.inc && (
              <p className="text-xxs md:text-sm text-gray-500">
                Company #: {companyByIdDataPublic?.inc}
              </p>
            )}
            {companyByIdDataPublic?.domain && (
              <p className="text-xxs md:text-sm text-gray-500 text-wrap">
                {companyByIdDataPublic?.domain}
              </p>
            )}
          </div>
        </div>

        {/* Invoice Info */}
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-6 ">
          <div>
            <h2 className="text-xs md:text-sm font-semibold text-gray-700">
              Invoice # {invoiceByIdDataPublic?.number}
            </h2>
            <p className="text-xxs md:text-sm text-gray-500">
              Invoice Date:{" "}
              {moment(invoiceByIdDataPublic?.date).format("D MMMM YYYY")}
            </p>
            <p className="text-xxs md:text-sm text-gray-500">
              Payment Month: {invoiceByIdDataPublic?.paymentMonth}
            </p>
          </div>
          <div className="text-left w-full md:w-[23%]">
            <h3 className="text-xs md:text-sm font-semibold text-gray-700">
              Invoice To
            </h3>
            <p className="text-xxs md:text-sm text-gray-500">
              Name: {userByUserNameData?.firstName}{" "}
              {userByUserNameData?.lastName}
            </p>
            <p className="text-xxs md:text-sm text-gray-500">
              Address: {userByUserNameData?.address}
            </p>
            <p className="text-xxs md:text-sm text-gray-500">
              Account: {userByUserNameData?.account}
            </p>
          </div>
        </div>

        {/* Task List */}
        {/* <div className="overflow-hidden mb-6">
          <table className="min-w-full table-auto text-left">
            <thead className="bg-gray-100 print:bg-gray-200">
              <tr>
                <th className="px-4 py-2 text-sm font-medium text-gray-700">
                  #
                </th>

                <th className="px-4 py-2 text-sm font-medium text-gray-700">
                  Task
                </th>
                <th className="px-4 py-2 text-sm font-medium text-gray-700">
                  Details
                </th>
                <th className="px-4 py-2 text-sm font-medium text-gray-700">
                  Hours Logged
                </th>
              </tr>
            </thead>
            <tbody>
              {invoiceByIdDataPublic?.items?.map((item, index) => (
                <tr key={index} className="border-b print:border-b">
                  <td className="px-4 py-2 text-sm text-gray-600">
                    {index + 1}
                  </td>

                  <td className="px-4 py-2 text-sm text-gray-600">
                    {item.task}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-600">
                    {item.detail}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-600">
                    {item.log} hrs
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}

        <div className="overflow-x-auto mb-6">
          <table className="min-w-full table-auto text-left">
            <thead className="bg-gray-100 hidden md:table-header-group ">
              <tr>
                <th className="px-4 py-2 text-sm font-medium text-gray-700">
                  #
                </th>
                <th className="px-4 py-2 text-sm font-medium text-gray-700">
                  Task
                </th>
                <th className="px-4 py-2 text-sm font-medium text-gray-700">
                  Details
                </th>
                <th className="px-4 py-2 text-sm font-medium text-gray-700">
                  Hours Logged
                </th>
              </tr>
            </thead>
            <tbody className="md:table-row-group">
              {invoiceByIdDataPublic?.items?.map((item, index) => (
                <tr
                  key={index}
                  className="border-b md:table-row block md:border-none"
                >
                  {/* Mobile view format */}
                  <div className="flex flex-col mb-4 md:hidden ">
                    <div className="flex justify-between px-4 py-2 text-xxs md:text-sm font-medium bg-gray-100 text-black">
                      <span>#</span>
                      <span>{index + 1}</span>
                    </div>
                    <div className="flex justify-between px-4 py-2 text-xxs md:text-sm font-medium text-gray-700 gap-4">
                      <span>Task</span>
                      <span>{item.task}</span>
                    </div>
                    <div className="flex justify-between px-4 py-2 text-xxs md:text-sm font-medium text-gray-700 gap-4">
                      <span>Details</span>
                      <span className="text-wrap">{item.detail}</span>
                    </div>
                    <div className="flex justify-between px-4 py-2 text-xxs md:text-sm font-medium text-gray-700">
                      <span>Hours Logged</span>
                      <span>{item.log} hrs</span>
                    </div>
                  </div>

                  {/* Desktop view format */}
                  <td className="hidden md:table-cell px-4 py-2 text-sm text-gray-600">
                    {index + 1}
                  </td>
                  <td className="hidden md:table-cell px-4 py-2 text-sm text-gray-600">
                    {item.task}
                  </td>
                  <td className="hidden md:table-cell px-4 py-2 text-sm text-gray-600">
                    {item.detail}
                  </td>
                  <td className="hidden md:table-cell px-4 py-2 text-sm text-gray-600">
                    {item.log} hrs
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Invoice Summary */}
        <div className="flex justify-end mb-6">
          <div className="w-full md:w-1/2 lg:w-1/3">
            <div className="flex justify-between items-center py-1">
              <p className="text-xxs md:text-sm text-gray-600">Bonus</p>
              <p className="text-xxs md:text-sm  text-gray-800">
                ${invoiceByIdDataPublic?.bonus}
              </p>
            </div>
            <div className="flex justify-between items-center py-1">
              <p className="text-xxs md:text-sm text-gray-600">Basic Pay</p>
              <p className="text-xxs md:text-sm  text-gray-800">
                ${invoiceByIdDataPublic?.basicPay}
                {/* {Number(invoiceByIdDataPublic?.total || 0) -
                  Number(invoiceByIdDataPublic?.bonus || 0)} */}
              </p>
            </div>
            <div className="flex justify-between items-center py-1">
              <p className="text-xxs md:text-sm font-semibold text-gray-800">
                Net Total
              </p>
              <p className="text-xxs md:text-sm font-semibold text-gray-800">
                $
                {Number(invoiceByIdDataPublic?.basicPay || 0) +
                  (invoiceByIdDataPublic?.bonus || 0)}
                {/* ${invoiceByIdDataPublic?.total} */}
              </p>
            </div>
            <div className="flex justify-between items-center py-1">
              <p className="text-xxs md:text-sm text-gray-600">Charges</p>
              <p className="text-xxs md:text-sm  text-gray-800">
                ${invoiceByIdDataPublic?.charges}
              </p>
            </div>
            <div className="flex justify-between items-center py-1">
              <p className="text-xxs md:text-sm font-semibold text-gray-800">
                Gross Total
              </p>
              <p className="text-xxs md:text-sm font-semibold text-gray-800">
                $
                {Number(invoiceByIdDataPublic?.basicPay || 0) +
                  (invoiceByIdDataPublic?.bonus || 0) +
                  (invoiceByIdDataPublic?.charges || 0)}
              </p>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="flex flex-col justify-center items-center border-t pt-6">
          {/* QR Code */}
          <div className="">
            <QRCode size={80} value={qrValue} />
          </div>
          <div className="text-center pt-4 text-xs md:text-sm text-gray-600 print:border-none">
            <p>Thank you for your business!</p>
            <p className="text-xxs md:text-xs">
              This self-generated payment confirmation, issued by the{" "}
              <span className="uppercase">{companyByIdDataPublic?.name}</span>,
              does not require a signature. You can verify it online at{" "}
              <Link
                to={`${liveUrl}view/${invoiceByIdDataPublic?.number}/${invoiceByIdDataPublic?.secret}`}
              >
                {companyByIdDataPublic?.domain}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
