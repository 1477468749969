import React, { useEffect, useState } from "react";
import BreadCrums from "../../common/BreadCrums";
import { clearMessage, templateUpload } from "../../store/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonForm from "../buttons/ButtonForm";

export default function UploadTemplateForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, message, error, statusCode } = useSelector(
    (state) => state.admin
  );

  const [formData, setFormData] = useState({
    name: "",
    template: null,
  });
  const [previewUrl, setPreviewUrl] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (
      file &&
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setFormData({
        ...formData,
        template: file,
      });

      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
    } else {
      alert("Please upload a DOCX file.");
    }
  };

  const validateForm = () => {
    const { name, template } = formData;

    if (!name || !template) {
      toast.error("All fields are required!");
      return false;
    }

    if (
      template.type !==
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      toast.error("Only DOCX files are allowed!");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const submissionData = new FormData();
      submissionData.append("name", formData.name);
      submissionData.append("docxTemplate", formData.template);

      dispatch(templateUpload(submissionData));
    }
  };

  useEffect(() => {
    if (statusCode === 201) {
      toast.success(message);
      navigate('/admin-dashboard/all-templates');
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [loading, message, error]);

  return (
    <>
      <div className="">
        <div className="flex justify-between items-center py-5">
          <BreadCrums
            breadCrum={[
              { name: "Admin Dashboard", path: "/admin-dashboard/" },
              {
                name: "Manage Templates",
                path: "/admin-dashboard/all-templates",
              },
              {
                name: "Upload Template",
                path: "/admin-dashboard/all-templates/upload-template",
              },
            ]}
          />
        </div>
      </div>

      <div className="w-full bg-white dark:bg-secondaryDarkBg rounded-xl p-3 mb-4 border-l-4 border-usetheme">
        <h1 className="text-[#18120F] dark:text-white text-lg md:text-lg font-semibold p-1">
          Upload New Template
        </h1>
        <form
          className="w-full border border-[#EBF0ED] rounded-lg p-3 uppercase space-y-2"
          onSubmit={handleSubmit}
        >
          {/* Name */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold flex items-center gap-2">
                Name
              </label>
              <input
                name="name"
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="text"
                placeholder="Template Name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* File Upload */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold flex items-center gap-2">
                Upload Template (DOCX only)
              </label>
              <input
                name="template"
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="file"
                accept=".docx"
                onChange={handleFileChange}
              />
            </div>
          </div>

          <div className="flex justify-end">
            <ButtonForm
              onClick={handleSubmit}
              loading={loading}
              text="Upload Template"
            />
          </div>
        </form>

        {/* DOCX Preview */}
        {/* {previewUrl && (
          <div className="mt-4">
            <h2 className="text-lg font-semibold">Template Preview</h2>
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                previewUrl
              )}`}
              width="100%"
              height="500px"
              frameBorder="0"
              title="DOCX Preview"
            />
          </div>
        )} */}
      </div>
    </>
  );
}
