import React from "react";
import { Link } from "react-router-dom";
import ButtonLink from "../buttons/ButtonLink";


export default function ContractTemplateCard({ item }) {


  return (
    <div className="w-full">
      <div className="relative bg-white dark:bg-secondaryDarkBg rounded-lg shadow-lg overflow-hidden group h-full flex flex-col">
       {/* PDF or DOCX Viewer */}
       {item?.pdfTemplate ? (
            <iframe
              src={`${item?.pdfTemplate}#toolbar=0&navpanes=0&scrollbar=0`}
              title={item?.name}
              className="w-full h-72 object-cover"
              frameBorder="0"
            ></iframe>
          ) : item?.docxTemplate?.endsWith(".docx") ? (
            <iframe
              src={`https://docs.google.com/gview?url=${item?.docxTemplate}&embedded=true&rand=${Math.random()}`}
              title={item?.name}
              className="w-full h-72 object-cover"
            ></iframe>
          ) : (
            <img
              src="/assets/images/no_image.jpg"
              alt={item?.name}
              className="w-full h-40 object-cover"
            />
          )}

        {/* Card Content */}
        <div className="p-4 flex-grow">
          <h2 className="text-base lg:text-xl font-semibold text-gray-800 dark:text-white">{item?.name}</h2>
          {/* <p className="text-gray-600 dark:text-white mt-2">
            Choose this template for your contract needs.
          </p> */}
        </div>
        {/* <Link to={`create-contract/${item?._id}/${item?.name}`} className="flex justify-center rounded-lg m-2 p-2 bg-usetheme hover:bg-usetheme_hover text-white cursor-pointer">Use</Link> */}
        <ButtonLink
            to={`create-contract/${item?._id}/${item?.name}`}
            text="Use this template"
            padding="py-2 px-3 md:px-6 md:py-2 m-2 text-center"
          />
        {/* Overlay with plus button */}
        {/* <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <button 
            onClick={() => {} }
            className="text-white text-4xl bg-gray-800 p-4 rounded-full hover:bg-gray-700">
            +
          </button>
        </div> */}
      </div>
    </div>
  );
}
