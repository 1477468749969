import React from "react";
import Root from "../pages/Root";
import authRoutes from "./authRoutes";
import ProtectedRoute from "../ProtectedRoute";
import AdminDashboardRoute from "./adminDashboardRoute";
import profileSettingRoutes from "./profileSettingRoutes";
import companiesManagementRoutes from "./companiesManagementRoutes";
import invoicesManagementRoutes from "./invoicesManagementRoutes";
import settingRoutes from "./settingRoutes";
import userManagementRoutes from "./userManagementRoutes";
import servicesRoutes from "./servicesRoutes";
import PublicViewRoutes from "./PublicViewRoutes";
import visitorsManagementRoutes from "./visitorsManagementRoutes";
import FourOFourPage from "../pages/not-found-pages/FourOFourPage";
import contractManagementRoutes from "./contractManagementRoutes";
import templateManagementRoutes from "./templateManagementRoute";
import clientManagementRoutes from "./clientManagementRoutes";

const mainRoutes = [
  {
    path: "/",
    element: <Root />,
    children: [
      ...authRoutes, 
      ...PublicViewRoutes,
      {
        path: "/admin-dashboard/",
        element: <ProtectedRoute />, 
        children: [
          ...AdminDashboardRoute,
          ...profileSettingRoutes,
          ...companiesManagementRoutes,
          ...invoicesManagementRoutes,
          ...settingRoutes,
          ...userManagementRoutes,
          ...servicesRoutes,
          ...visitorsManagementRoutes,
          ...contractManagementRoutes,
          ...templateManagementRoutes,
          ...clientManagementRoutes,
          {
            path: "*", 
            element: <FourOFourPage />, 
          },
        ],
      },
    ],
  },
];

export default mainRoutes;
