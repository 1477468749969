import React from "react";
import UploadTemplateForm from "../../components/template-management-component/UploadTemplateForm";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";

export default function UploadTemplatePage() {
  return (
    <>
      <AdminDashboardLayout>
        <div className="">
          <UploadTemplateForm />
        </div>
      </AdminDashboardLayout>
    </>
  );
}
