import React from 'react'
import AdminDashboardLayout from '../../layout/AdminDashboardLayout'
import SingleSubmittedContractView from '../../components/contract-management-components/SingleSubmittedContractView'

export default function SingleSubmittedContractViewPage() {
  return (
    <>
    <AdminDashboardLayout>
        <SingleSubmittedContractView />
    </AdminDashboardLayout>
    </>
  )
}
