import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCoreCompanySetting,
  updateCoreCompanySetting,
} from "../../store/adminSlice";
import Select from "react-select";
import ButtonForm from "../buttons/ButtonForm";

export default function ColorPicker({ onColorChange }) {
  const dispatch = useDispatch();
  const { appLoading, data, appMessage, appError } = useSelector((state) => state.admin);

  const [formData, setFormData] = useState({
    themeColor: "",
  });

  const [selectedColor, setSelectedColor] = useState("");

  const colorOptions = [
    { value: "#ed1c24", label: "Default" },
    { value: "#f16a70", label: "Green" },
    { value: "#b1d877", label: "Blue" },
    { value: "#4d4d4d", label: "Pink" },
  ];

  useEffect(() => {
    dispatch(getCoreCompanySetting());
  }, [dispatch]);

  useEffect(() => {
    if (data && data.coreSettings) {
      setFormData({
        themeColor: data.coreSettings.themeColor || "",
      });
      setSelectedColor(data.coreSettings.themeColor || "");
    }
  }, [data]);

  const handleColorChange = (selectedOption) => {
    setSelectedColor(selectedOption.value);
  };

  const handleSubmit = () => {
    dispatch(updateCoreCompanySetting({ themeColor: selectedColor }));
  };

  useEffect(() => {
    if (appMessage && !appError) {
      setFormData({ themeColor: selectedColor });
      document.documentElement.style.setProperty(
        "--tw-colors-usetheme",
        selectedColor
      );
      onColorChange(selectedColor);
    }
  }, [appMessage, appError, selectedColor, onColorChange]);

  return (
    <div className="py-4 bg-white dark:bg-secondaryDarkBg rounded-lg">
      <h3 className="font-semibold text-sm md:text-base dark:text-white">
        Choose a Theme Color
      </h3>

      <Select
        id="themeColor"
        name="themeColor"
        value={colorOptions.find((option) => option.value === selectedColor)}
        onChange={handleColorChange}
        options={colorOptions}
        isClearable={false}
        placeholder="Select a color"
        classNamePrefix="react-select"
        formatOptionLabel={(option) => (
          <div className="flex items-center">
            <span
              style={{
                backgroundColor: option.value,
                width: "16px",
                height: "16px",
                display: "inline-block",
                borderRadius: "50%",
                marginRight: "8px",
              }}
            ></span>
            <span>{option.label}</span>
          </div>
        )}
        styles={{
          control: (base) => ({
            ...base,
            padding: "4px",
            borderRadius: "8px",
            borderColor: "#D1D5DB",
            minHeight: "20px",
            boxShadow: "none",
            backgroundColor:
              document.documentElement.classList.contains("dark")
                ? "#1B2A41"
                : "#FAFAFA",
          }),
          valueContainer: (base) => ({
            ...base,
            padding: "0 4px",
            backgroundColor:
              document.documentElement.classList.contains("dark")
                ? "#1B2A41"
                : "#FAFAFA",
          }),
          singleValue: (base) => ({
            ...base,
            color: document.documentElement.classList.contains("dark")
              ? "#FAFAFA"
              : "#1F2937",
          }),
          menu: (base) => ({
            ...base,
            backgroundColor:
              document.documentElement.classList.contains("dark")
                ? "#1B2A41"
                : "#FFFFFF",
          }),
          option: (base, { isFocused, isSelected }) => ({
            ...base,
            backgroundColor: isSelected
              ? document.documentElement.classList.contains("dark")
                ? "#4B5563"
                : "#E5E7EB"
              : isFocused
              ? document.documentElement.classList.contains("dark")
                ? "#374151"
                : "#F3F4F6"
              : "transparent",
            color: document.documentElement.classList.contains("dark")
              ? "#FFFFFF"
              : "#1F2937",
          }),
        }}
        className="block w-full text-gray-600 text-xs"
      />
      <div className="mt-4">
        <h3 className="font-semibold text-sm md:text-base dark:text-white">Recent Colors</h3>
        <div className="flex flex-wrap gap-2 mt-2">
          {colorOptions.map((option) => (
            <div key={option.value} className="flex items-center">
              <div
                className=" w-6 h-6 md:w-8 md:h-8 rounded-full cursor-pointer"
                style={{ backgroundColor: option.value }}
                onClick={() => setSelectedColor(option.value)}
              ></div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-end mt-3">
        <ButtonForm
          onClick={handleSubmit}
          loading={appLoading}
          text="Apply Color"
        />
      </div>
    </div>
  );
}
