import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearMessage,
  getAllCompanies,
  getCoreCompanySetting,
  updateCoreCompanySetting,
} from "../../store/adminSlice";
import ButtonForm from "../buttons/ButtonForm";
import Select from "react-select";
import { applyThemeMode } from "../../constant";
import { toast } from "react-toastify";

export default function CompanySettingsForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    appLoading,
    appMessage,
    appError,
    data,
    appStatusCode,
    appCoreSetting,
    companiesForInvoiceForm,
  } = useSelector((state) => state.admin);

  const [formData, setFormData] = useState({
    adminApproval: false,
    sendApprovalNotification: false,
    themeMode: appCoreSetting?.coreSettings?.themeMode,
    currency: "Rs",
    defaultCompany: "",
    itemsPerPage: "",
  });

  const themeOptions = [
    { value: "auto", label: "Auto" },
    { value: "light", label: "Light" },
    { value: "dark", label: "Dark" },
  ];
  const currencyOptions = [
    { value: "Rs", label: "Rs - Pakistani Rupee" },
    { value: "€", label: "€ - Euro" },
    { value: "$", label: "$ - United States Dollar" },
    { value: "£", label: "£ - British Pound Sterling" },
    { value: "₹", label: "₹ - Indian Rupee" },
  ];

  useEffect(() => {
    dispatch(getCoreCompanySetting());
    dispatch(getAllCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (data && data.coreSettings) {
      setFormData({
        adminApproval: data.coreSettings.adminApproval || false,
        sendApprovalNotification:
          data.coreSettings.sendApprovalNotification || false,
        themeMode:
          data.coreSettings.themeMode || appCoreSetting?.coreSettings?.themeMode,
        currency: data.coreSettings.currency || "Rs",
        defaultCompany: data.coreSettings.defaultCompany || "",
        itemsPerPage: data.coreSettings.itemsPerPage || "",
      });
      applyThemeMode(formData.themeMode);
    }
  }, [data]);

  // useEffect(() => {
  //   applyThemeMode(formData.themeMode); // Apply the theme mode whenever it changes
  // }, [formData.themeMode]);

  // Prepare options for the company (sender)
  const companyOptions = companiesForInvoiceForm?.companies?.map((company) => ({
    value: company._id,
    label: company.name,
  }));

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleThemeChange = (selectedOption) => {
    setFormData({
      ...formData,
      themeMode: selectedOption.value,
    });
  };

  const handleCurrencyChange = (selectedOption) => {
    setFormData({
      ...formData,
      currency: selectedOption.value,
    });
  };

  const handleDefaultCompanyChange = (selectedOption) => {
    setFormData({
      ...formData,
      defaultCompany: selectedOption.value,
    });
  };

  const validateForm = () => {
    const { itemsPerPage } = formData;

    if (itemsPerPage <= 0 ) {
      toast.error("items Per Page Should not be zero");
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
    dispatch(updateCoreCompanySetting(formData)).then(() => {
      dispatch(getCoreCompanySetting());
    });
  }
  };

  return (
    <>
      <div className="w-full bg-white dark:bg-secondaryDarkBg  rounded-xl">
        <div className="flex justify-between items-center py-2">
          <h1 className="text-[#18120F] dark:text-white text-base md:text-lg font-semibold p-1">
            Company Settings
          </h1>
        </div>

        {/* Theme Selection Dropdown */}
        <div className="flex flex-col mt-4">
          <label className="text-[#6B6B6B] dark:text-white text-xs font-semibold mb-1">
            Theme Mode
          </label>
          <Select
            id="themeMode"
            name="themeMode"
            value={themeOptions.find(
              (option) => option.value === formData.themeMode
            )} // Find the corresponding option
            onChange={handleThemeChange}
            options={themeOptions}
            isClearable={false}
            classNamePrefix="react-select"
            styles={{
              control: (base) => ({
                ...base,
                padding: "4px",
                borderRadius: "8px",
                borderColor: "#D1D5DB",
                minHeight: "20px",
                boxShadow: "none",
                backgroundColor: document.documentElement.classList.contains(
                  "dark"
                )
                  ? "#1B2A41"
                  : "#FAFAFA",
              }),
              valueContainer: (base) => ({
                ...base,
                padding: "0 4px",
                backgroundColor: document.documentElement.classList.contains(
                  "dark"
                )
                  ? "#1B2A41"
                  : "#FAFAFA",
              }),
              singleValue: (base) => ({
                ...base,
                color: document.documentElement.classList.contains("dark")
                  ? "#FAFAFA"
                  : "#1F2937",
              }),
              menu: (base) => ({
                ...base,
                backgroundColor: document.documentElement.classList.contains(
                  "dark"
                )
                  ? "#1B2A41"
                  : "#FFFFFF",
              }),
              option: (base, { isFocused, isSelected }) => ({
                ...base,
                backgroundColor: isSelected
                  ? document.documentElement.classList.contains("dark")
                    ? "#4B5563"
                    : "#E5E7EB"
                  : isFocused
                  ? document.documentElement.classList.contains("dark")
                    ? "#374151"
                    : "#F3F4F6"
                  : "transparent",
                color: document.documentElement.classList.contains("dark")
                  ? "#FFFFFF"
                  : "#1F2937",
              }),
            }}
            className="block w-full text-gray-600 text-xs"
          />
        </div>
        {/* Currency Selection Dropdown */}
        <div className="flex flex-col mt-4">
          <label className="text-[#6B6B6B] dark:text-white text-xs font-semibold mb-1">
            Select Currency
          </label>
          <Select
            id="currency"
            name="currency"
            value={currencyOptions.find(
              (option) => option.value === formData.currency
            )} // Find the corresponding option
            onChange={handleCurrencyChange}
            options={currencyOptions}
            isClearable={false}
            classNamePrefix="react-select"
            styles={{
              control: (base) => ({
                ...base,
                padding: "4px",
                borderRadius: "8px",
                borderColor: "#D1D5DB",
                minHeight: "20px",
                boxShadow: "none",
                backgroundColor: document.documentElement.classList.contains(
                  "dark"
                )
                  ? "#1B2A41"
                  : "#FAFAFA",
              }),
              valueContainer: (base) => ({
                ...base,
                padding: "0 4px",
                backgroundColor: document.documentElement.classList.contains(
                  "dark"
                )
                  ? "#1B2A41"
                  : "#FAFAFA",
              }),
              singleValue: (base) => ({
                ...base,
                color: document.documentElement.classList.contains("dark")
                  ? "#FAFAFA"
                  : "#1F2937",
              }),
              menu: (base) => ({
                ...base,
                backgroundColor: document.documentElement.classList.contains(
                  "dark"
                )
                  ? "#1B2A41"
                  : "#FFFFFF",
              }),
              option: (base, { isFocused, isSelected }) => ({
                ...base,
                backgroundColor: isSelected
                  ? document.documentElement.classList.contains("dark")
                    ? "#4B5563"
                    : "#E5E7EB"
                  : isFocused
                  ? document.documentElement.classList.contains("dark")
                    ? "#374151"
                    : "#F3F4F6"
                  : "transparent",
                color: document.documentElement.classList.contains("dark")
                  ? "#FFFFFF"
                  : "#1F2937",
              }),
            }}
            className="block w-full text-gray-600 text-xs"
          />
        </div>

        <div className="flex flex-col mt-4">
          <label
            htmlFor="defaultCompany"
            className="block mb-1 text-[#6B6B6B] dark:text-white text-[12px] ms-1 font-semibold"
          >
            Default Company
          </label>
          <Select
            id="defaultCompany"
            name="defaultCompany"
            value={companyOptions?.find(
              (option) => option.value === formData.defaultCompany
            )}
            onChange={handleDefaultCompanyChange}
            options={companyOptions}
            isClearable
            placeholder="DefaultCompany"
            classNamePrefix="react-select"
            styles={{
              control: (base) => ({
                ...base,
                padding: "4px",
                borderRadius: "8px",
                borderColor: "#D1D5DB",
                minHeight: "20px",
                boxShadow: "none",
                backgroundColor: document.documentElement.classList.contains(
                  "dark"
                )
                  ? "#1B2A41"
                  : "#FAFAFA",
              }),
              valueContainer: (base) => ({
                ...base,
                padding: "0 4px",
                backgroundColor: document.documentElement.classList.contains(
                  "dark"
                )
                  ? "#1B2A41"
                  : "#FAFAFA",
              }),
              singleValue: (base) => ({
                ...base,
                color: document.documentElement.classList.contains("dark")
                  ? "#FAFAFA"
                  : "#1F2937",
              }),
              menu: (base) => ({
                ...base,
                backgroundColor: document.documentElement.classList.contains(
                  "dark"
                )
                  ? "#1B2A41"
                  : "#FFFFFF",
              }),
              option: (base, { isFocused, isSelected }) => ({
                ...base,
                backgroundColor: isSelected
                  ? document.documentElement.classList.contains("dark")
                    ? "#4B5563"
                    : "#E5E7EB"
                  : isFocused
                  ? document.documentElement.classList.contains("dark")
                    ? "#374151"
                    : "#F3F4F6"
                  : "transparent",
                color: document.documentElement.classList.contains("dark")
                  ? "#FFFFFF"
                  : "#1F2937",
              }),
            }}
            className="block w-full text-gray-600 text-xs"
          />
        </div>

        <div className="flex w-full flex-col md:gap-1 mt-4">
          <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold flex items-center gap-2">
            Items Per Page
          </label>
          <input
            name="itemsPerPage"
            className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
            type="text"
            placeholder="Items Per Page"
            value={formData.itemsPerPage}
            onChange={handleInputChange}
          />
        </div>

        {/* Toggle Switches */}
        <div className="mt-4 space-y-4">
          <div className="flex items-center justify-between">
            <span className="text-[#6B6B6B] dark:text-white text-xxs md:text-xs font-semibold">
              Admin approval for new accounts?
            </span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                name="adminApproval"
                checked={formData.adminApproval}
                onChange={handleInputChange}
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:bg-usetheme peer-focus:ring-blue-300"></div>
              <div className="absolute left-[2px] top-[2px] w-5 h-5 bg-white dark:bg-secondaryDarkBg rounded-full transition-transform peer-checked:translate-x-full"></div>
            </label>
          </div>

          <div className="flex items-center justify-between">
            <span className="text-[#6B6B6B] dark:text-white text-xxs md:text-xs font-semibold">
              Send approval notification?
            </span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                name="sendApprovalNotification"
                checked={formData.sendApprovalNotification}
                onChange={handleInputChange}
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:bg-usetheme peer-focus:ring-blue-300"></div>
              <div className="absolute left-[2px] top-[2px] w-5 h-5 bg-white dark:bg-secondaryDarkBg rounded-full transition-transform peer-checked:translate-x-full"></div>
            </label>
          </div>
        </div>

        <div className="flex justify-end mt-4">
          <ButtonForm
            onClick={handleSubmit}
            loading={appLoading}
            text="Save Changes"
          />
        </div>
      </div>
    </>
  );
}
