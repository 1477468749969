import React from "react";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";
import ServicesManagement from "../../components/services-management-components/ServicesManagement";
import BreadCrums from "../../common/BreadCrums";
import ButtonLink from "../../components/buttons/ButtonLink";

export default function AllServicesPage() {
  return (
    <AdminDashboardLayout>
      <div className="">
        <div className="">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center py-5 space-y-4 sm:space-y-0">
            {/* BreadCrumbs */}
            <BreadCrums
              breadCrum={[
                {
                  name: "Admin Dashboard",
                  path: "/admin-dashboard/",
                },
                {
                  name: "Services",
                  path: "/admin-dashboard/services",
                },
              ]}
            />
            {/* Add New Service Button */}
            <ButtonLink
              to="add-service"
              text="Add New Service"
              padding="px-4 py-2"
              className="sm:ml-4 sm:w-auto w-full"
            />
          </div>
        </div>
        {/* Services Management Section */}
        <ServicesManagement />
      </div>
    </AdminDashboardLayout>
  );
}
