import React, { useEffect, useState } from "react";
import NewUsersCard from "./NewUsersCard";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, getAllInactiveUser } from "../../store/adminSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../../common/Loader";

export default function NewUsers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, error, message, loading } = useSelector((state) => state.admin);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (data?.users) {
      setUsers(data?.users);
    }
  }, [data?.users]);

  useEffect(() => {
    dispatch(getAllInactiveUser());
  }, [dispatch]);

  const handleUserDeleted = (deletedUserId) => {
    setUsers((prevUsers) => prevUsers.filter((user) => user._id !== deletedUserId));
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(getAllInactiveUser());
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [loading, message, error, dispatch]);

  return (
    <div className="">
      {loading ? (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <div className={`grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4`}>
          {users?.length > 0 ? (
            users?.map((item) => (
              <NewUsersCard key={item._id} item={item} onUserDeleted={handleUserDeleted} />
            ))
          ) : (
            <p className="text-center text-lg font-semibold text-gray-600 dark:text-white">
              No Users Found
            </p>
          )}
        </div>
      )}
    </div>
  );
}
