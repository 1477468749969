import React from "react";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";
import UpdateCompanyForm from "../../components/companies-management-components/UpdateCompanyForm";

export default function UpdateCompanyPage() {
  return (
    <>
      <AdminDashboardLayout>
        <div className="sm:px-4">
          <UpdateCompanyForm />
        </div>
      </AdminDashboardLayout>
    </>
  );
}
