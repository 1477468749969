import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BreadCrums from "../../common/BreadCrums";
import { addService, clearMessage } from "../../store/adminSlice";
import ButtonForm from "../buttons/ButtonForm";

export default function CreateServiceForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, message, error, statusCode } = useSelector(
    (state) => state.admin
  );
  const inputRef = useRef(null);
  const [image, setImage] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    buttonText: "",
    addButton: "",
    buttonLink: "",
  });

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const { title, description, buttonText, addButton, buttonLink } = formData;

    if (!title || !description || !buttonText || !addButton || !buttonLink) {
      toast.error("All fields are required!");
      return false;
    }

    if (!image) {
      toast.error("Please upload an icon!");
      return false;
    }

    const allowedTypes = ["image/png", "image/jpeg", "image/svg+xml"];
    if (!allowedTypes.includes(image.type)) {
      toast.error("Only PNG, JPEG or SVG types are allowed!");
      return false;
    }

    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    if (image.size > maxSizeInBytes) {
      toast.error("Image size should not exceed 5MB!");
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formDataToSend = new FormData();
      formDataToSend.append("title", formData.title);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("buttonText", formData.buttonText);
      formDataToSend.append("addButton", formData.addButton);
      formDataToSend.append("buttonLink", formData.buttonLink);
      formDataToSend.append("icon", image); // Append the icon image file

      dispatch(addService(formDataToSend));
    }
  };

  useEffect(() => {
    if (statusCode === 201) {
      toast.success(message);
      navigate(-1);
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [loading, message, error]);
  return (
    <>
      <div className="">
        <div className="flex justify-between  items-center py-5">
          <BreadCrums
            breadCrum={[
              {
                name: "Admin Dashboard",
                path: "/admin-dashboard/",
              },
              {
                name: "Manage Services",
                path: "/admin-dashboard/services",
              },
              {
                name: "Add Service",
                path: "/admin-dashboard/manage-companies/add-service",
              },
            ]}
          />

          {/* <button
            onClick={handleSubmit}
            disabled={loading}
            className="text-white bg-usetheme text-xs md:text-sm h-12 font-semibold py-[7px] md:py-[10px] px-5 md:px-9 rounded-lg"
          >
            {!loading ? (
              "Add Service"
            ) : (
              <span className="loading loading-spinner loading-md"></span>
            )}
          </button> */}
        </div>
      </div>
      <div className="w-full bg-white dark:bg-secondaryDarkBg rounded-xl p-3 mb-4 border-l-4 border-usetheme">
        <h1 className=" text-[#18120F] dark:text-white text-lg md:text-lg font-semibold p-1">
          Add New Service
        </h1>
        <form
          className="w-full border border-[#EBF0ED] rounded-lg p-3 uppercase space-y-2"
          //   onSubmit={handleSubmit}
        >
          {/* Title and Icon */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Title
              </label>
              <input
                name="title"
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="Service Title"
                value={formData.title}
                onChange={handleInputChange}
              />
            </div>

            {/* Description */}
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Description
              </label>
              <input
                name="description"
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="Service Description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Button Text and Add Button */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Button Text
              </label>
              <input
                name="buttonText"
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="Add Now"
                value={formData.buttonText}
                onChange={handleInputChange}
              />
            </div>

            {/* Add Button */}
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Add Button
              </label>
              <input
                name="addButton"
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="true"
                value={formData.addButton}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Button Link */}
          <div className="flex w-full flex-col md:gap-1">
            <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
              Button Link
            </label>
            <input
              name="buttonLink"
              className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
              type="text"
              placeholder="www.example.com"
              value={formData.buttonLink}
              onChange={handleInputChange}
            />
          </div>

          {/* Upload Icon */}
          <div>
            <div className="max-w-36">
              <h2 className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold ">
                Upload Icon
              </h2>
              <div
                onClick={handleImageClick}
                className="w-full h-[95%] bg-[#FEFEFE] flex items-center justify-center border border-dashed border-[#E0E0E0] rounded relative"
              >
                {image ? (
                  <img className="" src={URL.createObjectURL(image)} alt="" />
                ) : (
                  <h2 className="text-[#BCBCBC] text-center text-xs md:text-sm">
                    Upload an Image
                  </h2>
                )}
              </div>
              <input
                ref={inputRef}
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleImageChange}
              />
            </div>
          </div>

          <div className="flex justify-end">
            <ButtonForm
              onClick={handleSubmit}
              loading={loading}
              text="Add Service"
            />
          </div>
        </form>
      </div>
    </>
  );
}
