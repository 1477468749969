import React, { useEffect, useState } from "react";
import AllUserCard from "./AllUserCard";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, getAllActiveUser } from "../../store/adminSlice";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";
import Pagination from "../../common/Pagination";

export default function AllUsers() {
  const dispatch = useDispatch();
  const { loading, message, error, activeUsers } = useSelector(
    (state) => state.admin
  );

  const [users, setUsers] = useState([]);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [sorting, setSorting] = useState(1);

  useEffect(() => {
    if (activeUsers?.users) {
      setUsers(activeUsers.users);
    }
  }, [activeUsers?.users]);

  // useEffect(() => {
  //   dispatch(
  //     getAllActiveUser({
  //       page: currentPage,
  //       limit: itemsPerPage,
  //       sort: sorting,
  //     })
  //   );
  // }, [dispatch]);

  const handleUserDeleted = (deletedUserId) => {
    setUsers((prevUsers) =>
      prevUsers.filter((user) => user._id !== deletedUserId)
    );
  };

  useEffect(() => {
    dispatch(
      getAllActiveUser({
        page: currentPage,
        limit: itemsPerPage,
        sort: sorting,
      })
    );
  }, [currentPage, itemsPerPage]);

  const totalPages = activeUsers?.pagination?.totalPages || 0;

  // pagination changes
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const handlePageSelectChange = (event) => {
    const selectedPage = parseInt(event.target.value, 10);
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(getAllActiveUser({
        page: currentPage,
        limit: itemsPerPage,
        sort: sorting,
      }));
    } else if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [message, error, dispatch]);

  return (
    <>
      <div className="">
        {loading ? (
          <div className="flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
            {users?.length > 0 ? (
              users?.map((item) => (
                <AllUserCard
                  key={item._id}
                  item={item}
                  onUserDeleted={handleUserDeleted}
                />
              ))
            ) : (
              <div className="col-span-2 sm:col-span-3 md:col-span-4 text-center">
                <p className="text-lg text-gray-600">No Users Found</p>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Pagination Controls */}
      {!loading && activeUsers?.users?.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          handlePageSelectChange={handlePageSelectChange}
        />
      )}
    </>
  );
}
