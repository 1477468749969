import React from "react";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";
import BreadCrums from "../../common/BreadCrums";
import NewUsers from "../../components/user-management-components/NewUsers";
import ButtonLink from "../../components/buttons/ButtonLink";

export default function NewUsersPage() {
  return (
    <>
      <AdminDashboardLayout>
        <div className="">
          <div className="">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center py-5 space-y-4 sm:space-y-0">
              <BreadCrums
                breadCrum={[
                  {
                    name: "Admin Dashboard",
                    path: "/admin-dashboard/",
                  },
                  {
                    name: "All Users",
                    path: "/admin-dashboard/all-users",
                  },
                  {
                    name: "New Users",
                  },
                ]}
              />

              <ButtonLink
                to="/admin-dashboard/all-users"
                text="Active Users"
              />
            </div>
          </div>
          <NewUsers />
        </div>
      </AdminDashboardLayout>
    </>
  );
}
