import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import BreadCrums from "../../common/BreadCrums";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  genInvoice,
  getAllActiveUserWithoutPagination,
  getAllCompanies,
  getInvoiceNumber,
  getInvoiceSecretNumber,
} from "../../store/adminSlice";
import ButtonForm from "../buttons/ButtonForm";
import Select from "react-select";
import { FaPlus } from "react-icons/fa6";

export default function CreateInvoiceForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    loading,
    message,
    error,
    statusCode,
    invoiceNextNumber,
    invoiceSecretNumber,
    companiesForInvoiceForm,
    activeUsers,
  } = useSelector((state) => state.admin);

  const dateInputRef = useRef(null);
  const monthInputRef = useRef(null);

  const handleDateWrapperClick = () => {
    if (dateInputRef.current) {
      dateInputRef.current.showPicker();
    }
  };

  const handleMonthWrapperClick = () => {
    if (monthInputRef.current) {
      monthInputRef.current.showPicker();
    }
  };

  useEffect(() => {
    dispatch(getInvoiceNumber());
    dispatch(getInvoiceSecretNumber());
    dispatch(getAllCompanies());
    dispatch(getAllActiveUserWithoutPagination());
  }, [dispatch]);

  const [formData, setFormData] = useState({
    number: invoiceNextNumber?.number,
    secret: invoiceSecretNumber?.secret,
    to: "",
    date: moment().format("YYYY-MM-DD"),
    bonus: "",
    charges: "",
    company: "",
    paymentMonth: moment().format("YYYY-MM"),
    basicPay: "",
  });

  const [tasks, setTasks] = useState([
    { index: 1, task: "", detail: "", log: "" },
  ]);

  useEffect(() => {
    setFormData({
      ...formData,
      number: invoiceNextNumber?.number,
      secret: invoiceSecretNumber?.secret,
    });
  }, [invoiceNextNumber, invoiceSecretNumber]);

  // Prepare options for the company (sender)
  const companyOptions = companiesForInvoiceForm?.companies?.map((company) => ({
    value: company._id,
    label: company.name,
  }));

  // Prepare options for the receiver (users)
  const userOptions = activeUsers?.users
    ?.filter((user) => user?.firstName && user?.lastName && user?.account)
    .map((user) => ({
      value: user?.username,
      label: user?.username,
    }));

  // Handler for react-select change
  const handleSelectChange = (selectedOption, action) => {
    handleInputChange({
      target: {
        name: action.name,
        value: selectedOption?.value || "",
      },
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleTaskChange = (index, event) => {
    const { name, value } = event.target;
    const updatedTasks = tasks.map((task, i) =>
      i === index ? { ...task, [name]: value } : task
    );
    setTasks(updatedTasks);
  };

  const addTask = () => {
    const newTask = {
      index: tasks.length + 1,
      task: "",
      detail: "",
      log: "0",
    };
    setTasks([...tasks, newTask]);
  };

  const removeTask = (index) => {
    if (tasks.length === 1) {
      toast.error("At least one task is required.");
      return;
    }
    setTasks(tasks.filter((_, i) => i !== index));
  };

  const handleDateChange = (event, setField) => {
    const date = event.target.value;
    if (setField === "date") {
      setFormData({
        ...formData,
        date: date,
      });
    }
  };

  const validateForm = () => {
    const requiredFields = [
      "date",
      "paymentMonth",
      "company",
      "to",
      "bonus",
      "charges",
      "basicPay",
    ];

    for (let field of requiredFields) {
      if (!formData[field]) {
        toast.error(`${field} is required`);
        return false;
      }
    }

    // Ensure at least one task has all fields filled
    const hasFilledTask = tasks.some(
      (task) =>
        task.task.trim() !== "" &&
        task.detail.trim() !== "" &&
        task.log.trim() !== ""
    );

    if (!hasFilledTask) {
      toast.error("At least one task with all fields filled is required.");
      return false;
    }

    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    const formattedPaymentMonth = moment(
      formData.paymentMonth,
      "YYYY-MM"
    ).format("MMMM YYYY");

    // Calculate total and gross total
    const calculatedTotal =
      Number(formData.bonus || 0) + Number(formData.basicPay || 0);
    const calculatedGrossTotal =
      Number(formData.basicPay || 0) + calculatedTotal;

    // Ensure there's at least one task
    if (tasks.length === 0) {
      toast.error("Please add at least one task.");
      return;
    }

    const payload = {
      ...formData,
      paymentMonth: formattedPaymentMonth,
      items: tasks,
      // total: calculatedTotal,
      grossTotal: calculatedGrossTotal,
    };

    dispatch(genInvoice(payload));
  };

  useEffect(() => {
    if (statusCode === 201) {
      toast.success(message);
      navigate(-1);
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [loading, message, error]);

  return (
    <>
      <div className="flex justify-between items-center py-5">
        <BreadCrums
          breadCrum={[
            { name: "Admin Dashboard", path: "/admin-dashboard/" },
            {
              name: "Manage Invoices",
              path: "/admin-dashboard/manage-invoices",
            },
            { name: "Create New Invoices" },
          ]}
        />
        {/* <Link
          to="/create-invoice"
          className="text-white bg-usetheme h-10 text-xs md:text-sm font-semibold py-3 md:py-[10px] px-4 md:px-6 rounded-lg"
          onClick={handleSubmit}
        >
          {!loading ? (
            "Generate Invoice"
          ) : (
            <span className="loading loading-spinner loading-md"></span>
          )}
        </Link> */}
      </div>

      <div className="w-full bg-white dark:bg-secondaryDarkBg rounded-xl p-3 mb-4 border-l-4 border-usetheme">
        <h1 className="text-[#18120F] dark:text-white text-lg md:text-lg font-semibold p-1">
          Create Invoice
        </h1>

        <form
          className="w-full border border-[#EBF0ED] rounded-lg p-3 uppercase space-y-2"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Number
              </label>
              <input
                name="number"
                value={formData.number}
                onChange={handleInputChange}
                className="bg-[#f2f2f2] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none cursor-not-allowed"
                type="number"
                disabled
              />
            </div>

            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Secret
              </label>
              <input
                name="secret"
                value={formData.secret}
                onChange={handleInputChange}
                className="bg-[#f2f2f2] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none cursor-not-allowed"
                type="text"
                disabled
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="w-full" onClick={handleDateWrapperClick}>
              <h2 className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Select Date
              </h2>
              <input
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg border border-[#EBF0ED] text-xs dark:text-white rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                name="date"
                value={formData.date}
                onChange={(event) => handleDateChange(event, "date")}
                type="date"
                ref={dateInputRef}
                max={moment().add(1, "years").format("YYYY-MM-DD")}
                min={moment().subtract(1, "years").format("YYYY-MM-DD")}
              />
            </div>
            <div className="w-full" onClick={handleMonthWrapperClick}>
              <h2 className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold ">
                Payment Month
              </h2>
              <input
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg border border-[#EBF0ED] text-xs dark:text-white rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                name="paymentMonth"
                value={formData.paymentMonth}
                onChange={handleInputChange}
                type="month"
                ref={monthInputRef}
                // disabled={isPaymentMonthDisabled}
                max={moment().add(1, "years").format("YYYY-MM")}
                min={moment().subtract(1, "years").format("YYYY-MM")}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="block w-full">
              <label
                htmlFor="company"
                className="block mb-1 text-[#6B6B6B] dark:text-white text-[12px] ms-1 font-semibold"
              >
                Company (Sender)
              </label>
              <Select
                id="company"
                name="company"
                value={companyOptions?.find(
                  (option) => option.value === formData.company
                )}
                onChange={handleSelectChange}
                options={companyOptions}
                isClearable
                placeholder="Choose a company"
                classNamePrefix="react-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    padding: "4px",
                    borderRadius: "8px",
                    borderColor: "#D1D5DB",
                    minHeight: "20px",
                    boxShadow: "none",
                    backgroundColor:
                      document.documentElement.classList.contains("dark")
                        ? "#1B2A41"
                        : "#FAFAFA",
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    padding: "0 4px",
                    backgroundColor:
                      document.documentElement.classList.contains("dark")
                        ? "#1B2A41"
                        : "#FAFAFA",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: document.documentElement.classList.contains("dark")
                      ? "#FAFAFA"
                      : "#1F2937",
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor:
                      document.documentElement.classList.contains("dark")
                        ? "#1B2A41"
                        : "#FFFFFF",
                  }),
                  option: (base, { isFocused, isSelected }) => ({
                    ...base,
                    backgroundColor: isSelected
                      ? document.documentElement.classList.contains("dark")
                        ? "#4B5563"
                        : "#E5E7EB"
                      : isFocused
                      ? document.documentElement.classList.contains("dark")
                        ? "#374151"
                        : "#F3F4F6"
                      : "transparent",
                    color: document.documentElement.classList.contains("dark")
                      ? "#FFFFFF"
                      : "#1F2937",
                  }),
                }}
                className="block w-full text-gray-600 text-xs"
              />
            </div>

            <div className="block w-full">
              <label
                htmlFor="to"
                className="block mb-1 text-[#6B6B6B] dark:text-white text-[12px] ms-1 font-semibold"
              >
                To (Receiver)
              </label>
              <Select
                id="to"
                name="to"
                value={userOptions?.find(
                  (option) => option.value === formData.to
                )}
                onChange={handleSelectChange}
                options={userOptions}
                isClearable
                placeholder="Choose an option"
                classNamePrefix="react-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    padding: "4px",
                    borderRadius: "8px",
                    borderColor: "#D1D5DB",
                    minHeight: "20px",
                    boxShadow: "none",
                    backgroundColor:
                      document.documentElement.classList.contains("dark")
                        ? "#1B2A41"
                        : "#FAFAFA",
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    padding: "0 4px",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: document.documentElement.classList.contains("dark")
                      ? "#FAFAFA"
                      : "#1F2937",
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor:
                      document.documentElement.classList.contains("dark")
                        ? "#1B2A41"
                        : "#FFFFFF", // Background color of the dropdown
                  }),
                  option: (base, { isFocused, isSelected }) => ({
                    ...base,
                    backgroundColor: isSelected
                      ? document.documentElement.classList.contains("dark")
                        ? "#4B5563"
                        : "#E5E7EB"
                      : isFocused
                      ? document.documentElement.classList.contains("dark")
                        ? "#374151"
                        : "#F3F4F6"
                      : "transparent",
                    color: document.documentElement.classList.contains("dark")
                      ? "#FFFFFF"
                      : "#1F2937",
                  }),
                }}
                className="block w-full text-gray-600 text-xs"
              />
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
              Tasks
            </label>

            {tasks.map((task, index) => (
              <div key={index} className="border p-2 rounded-lg mb-2 w-full">
                <div className="flex flex-wrap justify-between items-center">
                  <div className="flex flex-col w-full md:w-[20%]">
                    <label className="text-[#6B6B6B] dark:text-white text-[12px] font-semibold">
                      Task
                    </label>
                    <input
                      name="task"
                      value={task.task}
                      onChange={(event) => handleTaskChange(index, event)}
                      className="bg-[#FAFAFA] dark:bg-secondaryDarkBg dark:text-white border border-[#EBF0ED] text-xs rounded-lg w-full p-2 outline-none"
                      type="text"
                      placeholder="Update the navbar code"
                    />
                  </div>

                  <div className="flex flex-col w-full md:w-[65%]">
                    <label className="text-[#6B6B6B] dark:text-white text-[12px] font-semibold">
                      Detail
                    </label>
                    <input
                      name="detail"
                      value={task.detail}
                      onChange={(event) => handleTaskChange(index, event)}
                      className="bg-[#FAFAFA] dark:bg-secondaryDarkBg dark:text-white border border-[#EBF0ED] text-xs rounded-lg w-full p-2 outline-none"
                      type="text"
                      placeholder="Added new menus to navbar and change the color themes for menus background"
                    />
                  </div>

                  <div className="flex flex-col w-full md:w-[11%]">
                    <label className="text-[#6B6B6B] dark:text-white text-[12px] font-semibold">
                      Logged Hours
                    </label>
                    <input
                      name="log"
                      value={task.log}
                      onChange={(event) => handleTaskChange(index, event)}
                      className="bg-[#FAFAFA] dark:bg-secondaryDarkBg dark:text-white border border-[#EBF0ED] text-xs rounded-lg w-full p-2 outline-none"
                      type="number"
                      min={0}
                    />
                  </div>

                  <div className=" w-full md:w-auto flex justify-center items-center mt-4 rounded-md bg-[#e5cbcb] p-1">
                    <button
                      type="button"
                      className="text-red-500"
                      onClick={() => removeTask(index)}
                    >
                      <AiOutlineDelete className="text-white" size={20} />
                    </button>
                  </div>
                </div>
              </div>
            ))}

            <div className="flex justify-end gap-2">
              <button
                type="button"
                className="text-white bg-[#e5cbcb] py-1 px-4 rounded-md"
                onClick={addTask}
              >
                <FaPlus />
              </button>
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Logged Hours
              </label>
              <input
                name="hours"
                value={tasks?.reduce(
                  (total, task) => total + Number(task.log || 0),
                  0
                )}
                onChange={handleInputChange}
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="number"
                min={0}
                disabled // Disables the input field
              />
            </div>

            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Charges
              </label>
              <input
                name="charges"
                value={formData.charges}
                onChange={handleInputChange}
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="number"
                min={0}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Bonus
              </label>
              <input
                name="bonus"
                value={formData.bonus}
                onChange={handleInputChange}
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="number"
                min={0}
              />
            </div>

            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Basic Pay
              </label>
              <input
                name="basicPay"
                value={formData.basicPay}
                onChange={handleInputChange}
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="number"
                min={0}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className=" text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Total
              </label>
              <input
                name="total"
                value={
                  Number(formData.bonus || 0) + Number(formData.basicPay || 0)
                }
                onChange={handleInputChange}
                className="bg-[#f2f2f2] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none cursor-not-allowed"
                type="number"
                min={0}
                disabled
              />
            </div>
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Gross Total
              </label>
              <input
                name="grossTotal"
                value={
                  Number(formData.basicPay || 0) +
                  (Number(formData.bonus || 0) + Number(formData.charges || 0))
                }
                onChange={handleInputChange}
                className="bg-[#f2f2f2] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none cursor-not-allowed"
                type="number"
                disabled
              />
            </div>
          </div>

          <div className="flex justify-end">
            <ButtonForm
              onClick={handleSubmit}
              loading={loading}
              text="Create Invoice"
            />
          </div>
        </form>
      </div>
    </>
  );
}
