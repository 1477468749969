import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { clearMessage, getTemplateByID, updateTemplate } from "../../store/adminSlice";
import BreadCrums from "../../common/BreadCrums";
import { toast } from "react-toastify";
import ButtonForm from "../buttons/ButtonForm";

export default function UpdateTemplateForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, message, error, statusCode, data } = useSelector(
    (state) => state.admin
  );

  const [formData, setFormData] = useState({
    name: "",
    template: null,
  });
  
  const [initialData, setInitialData] = useState({
    name: "",
    template: null,
  });  

  const [previewUrl, setPreviewUrl] = useState(null);

  useEffect(() => {
    if (id) {
      dispatch(getTemplateByID(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (data && data?._id === id) {
      setFormData({
        name: data.name || "",
        template: data.template || "",
      });
      setInitialData({
        name: data.name || "",
        template: data.template || "",
      });
    }
  }, [data, id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (
      file &&
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setFormData({
        ...formData,
        template: file,
      });

      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
    } else {
      toast.error("Please upload a DOCX file.");
    }
  };

  const validateForm = () => {
    const { name, template } = formData;
  
    if (!name || !template) {
      toast.error("All fields are required!");
      return false;
    }
  
    
    if (template instanceof File && template.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      toast.error("Only DOCX files are allowed!");
      return false;
    }
  
    return true;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (validateForm()) {
      const submissionData = new FormData();
      
      
      if (formData.name !== initialData.name) {
        submissionData.append("name", formData.name);
      }
  
     
      if (formData.template instanceof File) {
        submissionData.append("template", formData.template);
      }
  
      
      if (submissionData.has("name") || submissionData.has("template")) {
        dispatch(updateTemplate({ id, data: submissionData }));
      } else {
        toast.info("No changes to update.");
      }
    }
  };
  

  useEffect(() => {
    if (statusCode === 200 && message === "Template updated successfully") {
      toast.success(message);
      navigate(-1);
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [loading, message, error]);
  
  return (
    <>
      <div className="">
        <div className="flex justify-between items-center py-5">
          <BreadCrums
            breadCrum={[
              { name: "Admin Dashboard", path: "/admin-dashboard/" },
              {
                name: "Manage Templates",
                path: "/admin-dashboard/all-templates",
              },
              {
                name: "Update Template",
              },
            ]}
          />
        </div>
      </div>

      <div className="w-full bg-white rounded-xl p-3 mb-4 border-l-4 border-usetheme">
        <h1 className="text-[#18120F] text-lg md:text-lg font-semibold p-1">
          Upload New Template
        </h1>
        <form
          className="w-full border border-[#EBF0ED] rounded-lg p-3 uppercase space-y-2"
          onSubmit={handleSubmit}
        >
          {/* Name */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold flex items-center gap-2">
                Name
              </label>
              <input
                name="name"
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="text"
                placeholder="Template Name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* File Upload */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold flex items-center gap-2">
                Upload Template (DOCX only)
              </label>
              <input
                name="template"
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="file"
                accept=".docx"
                onChange={handleFileChange}
              />
            </div>
          </div>

          <div className="flex justify-end">
            <ButtonForm
              onClick={handleSubmit}
              loading={loading}
              text="Update Template"
            />
          </div>
        </form>

        {/* DOCX Preview */}
        {previewUrl && (
          <div className="mt-4">
            <h2 className="text-lg font-semibold">Template Preview</h2>
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                previewUrl
              )}`}
              width="100%"
              height="500px"
              frameBorder="0"
              title="DOCX Preview"
            />
          </div>
        )}
      </div>
    </>
  );
}
