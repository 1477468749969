import React, { useEffect, useState } from "react";
import BreadCrums from "../../common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  getAllTemplates,
  searchTemplate,
} from "../../store/adminSlice";
import TemplateCard from "./TemplateCard";
import Loader from "../../common/Loader";
import ButtonLink from "../buttons/ButtonLink";
import { toast } from "react-toastify";

export default function AllTemplates() {
  const dispatch = useDispatch();
  const { loading, data, statusCode, message, error, appCoreSetting } = useSelector(
    (state) => state.admin
  );

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(appCoreSetting?.coreSettings?.itemsPerPage);
    const [sorting, setSorting] = useState(1);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [Templates, setTemplates] = useState([]);

  useEffect(() => {
    dispatch(getAllTemplates({ page: currentPage, limit: itemsPerPage, sort: sorting }));
  }, [dispatch]);

  useEffect(() => {
    if (data?.templates) {
      setTemplates(data?.templates);
    }
  }, [data?.templates]);

  const handleTemplateDeleted = (deletedTemplateId) => {
    setTemplates((prevTemplates) =>
      prevTemplates.filter((template) => template._id !== deletedTemplateId)
    );
  };

  const handleSearch = (e) => {
    const query = e.target.value.trim();
    setSearchQuery(query);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (e.key === "Enter") {
      if (query === "") {
        dispatch(getAllTemplates({ page: currentPage, limit: itemsPerPage, sort: sorting }));
      } else {
        dispatch(searchTemplate(query));
      }
    } else {
      const timeout = setTimeout(() => {
        if (query === "") {
          dispatch(getAllTemplates({ page: currentPage, limit: itemsPerPage, sort: sorting }));
        } else {
          dispatch(searchTemplate(query));
        }
      }, 1000);

      setSearchTimeout(timeout);
    }
  };

  useEffect(() => {
    dispatch(
      getAllTemplates({ page: currentPage, limit: itemsPerPage, sort: sorting })
    );
  }, [currentPage, itemsPerPage]);

  const totalPages = data?.pagination?.totalPages || 0;

  // pagination changes
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const handlePageSelectChange = (event) => {
    const selectedPage = parseInt(event.target.value, 10);
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    if (statusCode === 200 && message) {
      toast.success(message);
    } else if (error) {
      toast.error(error);
    }

    dispatch(clearMessage());
  }, [statusCode, message, error, dispatch]);

  return (
    <>
      <div className="">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center py-5 space-y-4 sm:space-y-0">
          <BreadCrums
            breadCrum={[
              { name: "Admin Dashboard", path: "/admin-dashboard/" },
              { name: "Templates", path: "" },
            ]}
          />
          <ButtonLink to="upload-template" text="Add New Template" />
        </div>
        <div className="relative flex items-center w-full mb-4">
          <img
            src="/assets/icons/search.svg"
            alt="Search Icon"
            className="absolute left-3 text-[#C19A6B]"
          />
          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white dark:bg-secondaryDarkBg border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] dark:text-white font-medium text-sm md:text-base"
            type="search"
            placeholder="Search Templates ..."
            onChange={handleSearch}
            onKeyDown={handleSearch}
          />
        </div>
      </div>

      <div className=" py-4">
        {loading ? (
          <div className="flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6">
            {Templates?.length > 0 ? (
              Templates?.map((item) => (
                <TemplateCard
                  key={item._id}
                  item={item}
                  onTemplateDeleted={handleTemplateDeleted}
                />
              ))
            ) : (
              <div className="dark:text-white">No Templates Found</div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
