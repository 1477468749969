import React from "react";
import VisitorUpdationForm from "../../components/visitors-management-components/VisitorUpdationForm";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";

export default function VisitorUpdationPage() {
  return (
    <>
      <AdminDashboardLayout>
        <div className="">
          <VisitorUpdationForm />
        </div>
      </AdminDashboardLayout>
    </>
  );
}
