import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "./adminSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web

// Persist configuration
const persistConfig = {
  key: "admin", 
  storage, 
  whitelist: ["appCoreSetting"], 
};

// Persisted reducer
const persistedAdminReducer = persistReducer(persistConfig, adminReducer);

// Configure the store
const store = configureStore({
  reducer: {
    admin: persistedAdminReducer, 
  },
});

export const persistor = persistStore(store); // Create the persistor

export default store;
