import React, { useState } from "react";
import { Link } from "react-router-dom";
import DeletePopup from "../../common/DeletePopup";
import { deleteSubmittedContract } from "../../store/adminSlice";
import { useDispatch } from "react-redux";
import ActionIconButton from "../buttons/ActionIconButton";

export default function SubmittedContractCard({ item, onContractDeleted }) {
  const dispatch = useDispatch();
  const contractValues = item?.values ? JSON.parse(item.values) : {};
  const [loading, setLoading] = useState(false);

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedContractId, setSelectedContractId] = useState(null);

  const handleDeleteClick = (id) => {
    setSelectedContractId(id);
    setShowDeletePopup(true);
  };

  const handleDownload = async (e) => {
    setLoading(true); 

    const pdfUrl = item?.pdfFile;

    
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      window.open(pdfUrl, "_blank");
    } else {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "document.pdf";

      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    setLoading(false); 
  };

  const handleDeleteConfirmation = () => {
    dispatch(deleteSubmittedContract(selectedContractId)).then(() => {
      onContractDeleted(selectedContractId);
    });
    setShowDeletePopup(false);
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false);
  };

  return (
    <>
      <div className="w-full max-w-md mx-auto">
        <div className="relative bg-white dark:bg-secondaryDarkBg rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 overflow-hidden flex flex-col">
          {/* File Preview Section */}
          {item?.pdfFile ? (
            <iframe
              src={`${item?.pdfFile}#toolbar=0&navpanes=0&scrollbar=0`}
              title={item?.name}
              className="w-full h-64 object-cover"
              frameBorder="0"
            ></iframe>
          ) : item?.docxFile?.endsWith(".docx") ? (
            <iframe
              src={`https://docs.google.com/gview?url=${
                item?.docxFile
              }&embedded=true&rand=${Math.random()}`}
              title={item?.name}
              className="w-full h-64 object-cover"
            ></iframe>
          ) : (
            <img
              src="/assets/images/no_image.jpg"
              alt={item?.name}
              className="w-full h-40 object-cover"
            />
          )}

          {/* Card Content */}
          <div className="p-3 flex justify-between items-center border-t border-t-[#EBF0ED]">
            <h2 className="text-base lg:text-xl font-semibold text-gray-900 dark:text-white capitalize">
              {contractValues.name ? contractValues.name : "name Unavailable"}
            </h2>

            <div className="flex gap-2 items-center">
              {/* Download Button */}
              {item?.pdfFile &&
                // <button
                //   onClick={async (e) => {
                //     e.preventDefault();

                //     const response = await fetch(item.pdfFile);
                //     const blob = await response.blob();
                //     const url = window.URL.createObjectURL(blob);

                //     const link = document.createElement("a");
                //     link.href = url;
                //     link.setAttribute("download", "document.pdf");
                //     document.body.appendChild(link);
                //     link.click();
                //     document.body.removeChild(link);
                //     window.URL.revokeObjectURL(url);
                //   }}
                //   className="text-gray-600 hover:text-blue-600 cursor-pointer p-3 border-[#EBF0ED] dark:border-white border rounded-xl bg-[#FAFAFA] dark:bg-secondaryDarkBg"
                // >
                //   <img
                //     className="max-w-8 max-h-8 md:max-w-16 md:max-h-16"
                //     src="/assets/icons/download-icon.svg"
                //     alt="Download"
                //   />
                // </button>
                loading ? (
                  "loading"
                )
                  :
                  (
                    <ActionIconButton
                    onClick={handleDownload}
                    iconPath="/assets/icons/download-icon.svg"
                    alt="Download"
                  />
                  )
                }

              <ActionIconButton
                to={`update/${item?._id}`}
                iconPath="/assets/icons/edit-icon.svg"
                alt="edit"
              />
              <ActionIconButton
                to={`view/${item?._id}`}
                iconPath="/assets/icons/view-icon.svg"
                alt="view"
              />
              <ActionIconButton
                onClick={() => handleDeleteClick(item._id)}
                iconPath="/assets/icons/delete-icon.svg"
                alt="delete"
              />
              {/* <Link
                to={`update/${item?._id}`}
                className="text-gray-600 hover:text-blue-600 cursor-pointer p-3 border-[#EBF0ED] dark:border-white border rounded-xl bg-[#FAFAFA] dark:bg-secondaryDarkBg"
              >
                <img
                  className="max-w-8 max-h-8 md:max-w-16 md:max-h-16 dark:filter dark:invert"
                  src="/assets/icons/edit-icon.svg"
                  alt=""
                />
              </Link> */}

              {/* <Link
                to={`view/${item?._id}`}
                className="text-gray-600 hover:text-blue-600 cursor-pointer p-3 border-[#EBF0ED] dark:border-white border rounded-xl bg-[#FAFAFA] dark:bg-secondaryDarkBg"
              >
                <img
                  className="max-w-8 max-h-8 md:max-w-16 md:max-h-16"
                  src="/assets/icons/view-icon.svg"
                  alt=""
                />
              </Link> */}
              {/* <button
                onClick={() => handleDeleteClick(item._id)}
                className="text-gray-600 hover:text-red-600 cursor-pointer p-3 border-[#EBF0ED] dark:border-white border rounded-xl bg-[#FAFAFA] dark:bg-secondaryDarkBg"
              >
                <img
                  className="max-w-8 max-h-8 md:max-w-16 md:max-h-16"
                  src="/assets/icons/delete-icon.svg"
                  alt=""
                />
              </button> */}
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Popup */}
      {showDeletePopup && (
        <DeletePopup
          isVisible={showDeletePopup}
          onDeleteConfirmation={handleDeleteConfirmation}
          onDeleteCancel={handleDeleteCancel}
        />
      )}
    </>
  );
}
