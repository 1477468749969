import React from "react";
import TemplateViewCard from "../../components/template-management-component/TemplateViewCard";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";

export default function ViewTemplatePage() {
  return (
    <>
      <AdminDashboardLayout>
        <div className="">
          <TemplateViewCard />
        </div>
      </AdminDashboardLayout>
    </>
  );
}
