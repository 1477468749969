import React from "react";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";
import UpdateSubmittedContract from "../../components/contract-management-components/UpdateSubmittedContract";

export default function UpdateSubmittedContractPage() {
  return (
    <>
      <AdminDashboardLayout>
        <UpdateSubmittedContract />
      </AdminDashboardLayout>
    </>
  );
}
