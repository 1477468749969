import React, { useEffect } from "react";
import BreadCrums from "../../common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import {getAllTemplatesForContract } from "../../store/adminSlice";
import Loader from "../../common/Loader";
import ContractTemplateCard from "./ContractTemplateCard";

export default function AllContractTemplates() {
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(getAllTemplatesForContract());
  }, [dispatch]);

  return (
    <>
      <div className="mb-8">
        <div className="flex justify-between items-center py-5">
          <BreadCrums
            breadCrum={[
              { name: "Admin Dashboard", path: "/admin-dashboard/" },
              { name: "Templates", path: "" },
            ]}
          />
        </div>
        <div className="relative flex items-center w-full mb-4">
          <img
            src="/assets/icons/search.svg"
            alt="Search Icon"
            className="absolute left-3 text-[#C19A6B]"
          />
          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white dark:bg-secondaryDarkBg border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-medium text-sm md:text-base"
            type="search"
            placeholder="Search Contracts Template ..."
          />
        </div>
      </div>

      <div className=" py-4 mt-4">
        {loading ? (
          <div className="flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6">
            {data?.templates?.length > 0
              ? data?.templates?.map((item) => <ContractTemplateCard key={item._id} item={item} />)
              : "No Templates Found"}
          </div>
        )}
      </div>
    </>
  );
}
