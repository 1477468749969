import React from 'react'
import AdminDashboardLayout from '../../layout/AdminDashboardLayout'
import CreateContract from '../../components/contract-management-components/CreateContract'

export default function CreateContractPage() {
  return (
    <>
    <AdminDashboardLayout >
        <CreateContract />
    </AdminDashboardLayout>
    </>
  )
}
