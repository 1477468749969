import React from 'react'
import PublicInvoiceViewTemplate from '../../components/invoices-management-components/PublicInvoiceViewTemplate'

export default function PublicInvoiceViewPage() {
  return (
    <>
    <PublicInvoiceViewTemplate />
    </>
  )
}
