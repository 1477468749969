import React from "react";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";
import AllClients from "../../components/clients-management-components/AllClients";

export default function AllClientsPage() {
  return (
    <>
      <AdminDashboardLayout>
        <AllClients />
      </AdminDashboardLayout>
    </>
  );
}
