import React from "react";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";
import SubmittedContracts from "../../components/contract-management-components/SubmittedContracts";

export default function SubmittedContractsPage() {
  return (
    <>
      <AdminDashboardLayout>
        <SubmittedContracts />
      </AdminDashboardLayout>
    </>
  );
}
