import React from "react";
import { Link } from "react-router-dom";

export default function ActionIconButton({
  to = "",
  className = "text-gray-600 hover:text-blue-600 cursor-pointer p-2 border-[#EBF0ED] dark:border-white border rounded-lg bg-[#FAFAFA] dark:bg-secondaryDarkBg",
  iconStyle = "max-w-8 max-h-8 md:max-w-16 md:max-h-16 dark:filter dark:invert",
  iconPath = "/assets/icons/edit-icon.svg",
  alt = "icon",
  onClick,
}) {
  return (
    <>
      <Link to={to} onClick={onClick} className={className}>
        <img
          className={iconStyle}
          src={iconPath}
          //   src="/assets/icons/edit-icon.svg"
          alt={alt}
        />
      </Link>
    </>
  );
}
