import React, { useEffect, useState } from "react";
import BreadCrums from "../../common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { clearMessage, getVisitorByID, updateVisitor } from "../../store/adminSlice";
import ButtonForm from "../buttons/ButtonForm";
import { toast } from "react-toastify";
import Select from "react-select";


const skills = [
  { _id: "1", name: "Basic" },
  { _id: "2", name: "Intermediate" },
  { _id: "3", name: "Expert" },
  { _id: "4", name: "Pro" },
];
const joining_Avalability = [
  { _id: "1", name: "ASAP" },
  { _id: "2", name: "1 Month" },
  { _id: "3", name: "3 Months" },
  { _id: "4", name: "6 Months" },
  { _id: "5", name: "Year" },
];
const workplace_Preference = [
  { _id: "1", name: "On-site" },
  { _id: "2", name: "Remote" },

];

export default function VisitorUpdationForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, message, error, statusCode, data } = useSelector(
    (state) => state.admin
  );

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    speciality: "",
    skillLevel: "",
    joiningAvalability: "",
    workplacePreference: "",
    expectedSalary:""
  });

  const skillsOptions = skills?.map((skill) => ({
    value: skill.name,
    // value: skill._id,
    label: skill.name,
  }));
  const joiningAvalability = joining_Avalability?.map((av) => ({
    value: av.name,
    label: av.name,
  }));
  const workplacePreference = workplace_Preference?.map((wp) => ({
    value: wp.name,
    label: wp.name,
  }));

  useEffect(() => {
    if (id) {
      dispatch(getVisitorByID(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (data && data?._id === id) {
      setFormData({
        name: data.name || "",
        email: data.email || "",
        phone: data.phone || "",
        speciality: data.speciality || "",
        skillLevel: data.skillLevel || "",
        joiningAvalability: data.joiningAvalability || "",
        workplacePreference: data.workplacePreference || "",
        expectedSalary:data.expectedSalary || ""
      });
    }
  }, [data, id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (selectedOption, action) => {
    handleInputChange({
      target: {
        name: action.name,
        value: selectedOption?.value || "",
      },
    });
  };

  const validateForm = () => {
    const { name, email, phone, speciality, skillLevel, joiningAvalability, workplacePreference, expectedSalary } = formData;

    if (!name || !email || !phone || !speciality || !skillLevel || !joiningAvalability || !workplacePreference || !expectedSalary) {
      toast.error("All fields are required!");
      return false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      toast.error("Please enter a valid email address!");
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // for form-data
      //   const formDataToSend = new FormData();
      //   formDataToSend.append("name", formData.name);
      //   formDataToSend.append("email", formData.email);
      //   formDataToSend.append("phone", formData.phone);
      //   formDataToSend.append("speciality", formData.speciality);

      // raw data
      const formDataToSend = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        speciality: formData.speciality,
        skillLevel: formData.skillLevel,
        joiningAvalability: formData.joiningAvalability,
        workplacePreference: formData.workplacePreference,
        expectedSalary: formData.expectedSalary,
      };

      dispatch(updateVisitor({ id, data: formDataToSend }));
    }
  };

  useEffect(() => {
    if (statusCode === 200) {
      toast.success(message);
      navigate(-1);
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [loading, message, error, statusCode, dispatch, navigate]);

  return (
    <>
      <div className="">
        <div className="flex justify-between  items-center py-5">
          <BreadCrums
            breadCrum={[
              {
                name: "Admin Dashboard",
                path: "/admin-dashboard/",
              },
              {
                name: "Talent Pool",
                path: "/admin-dashboard/talent-pool",
              },
              {
                name: "Update Talent",
              },
            ]}
          />
        </div>
      </div>
      <div className="w-full bg-white dark:bg-secondaryDarkBg rounded-xl p-3 mb-4  border-l-4 border-usetheme">
        <h1 className=" text-[#18120F] dark:text-white text-lg md:text-lg font-semibold p-1">
          Update Talent
        </h1>
        <form
          className="w-full border border-[#EBF0ED] rounded-lg p-3 uppercase space-y-2"
          onSubmit={handleSubmit}
        >
          {/* Name and email */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold flex items-center gap-2">
                Name
              </label>

              <input
                name="name"
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="khan"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>

            {/* Email */}
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold flex gap-2">
                Email{" "}
              </label>
              <input
                name="email"
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="email"
                placeholder="netchain@gmail.com"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Phone and speciality */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Phone
              </label>
              <input
                name="phone"
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="+923038772575"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>

            {/* spacilaity */}
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold">
                Speciality
              </label>
              <input
                name="speciality"
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="Next Js Developer"
                value={formData.speciality}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* skill level and joing availablity */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="block w-full">
              <label
                htmlFor="skillLevel"
                className="block mb-1 text-[#6B6B6B] dark:text-white text-[12px] ms-1 font-semibold"
              >
                Skill Level
              </label>
              <Select
                id="skillLevel"
                name="skillLevel"
                value={skillsOptions?.find(
                  (option) => option.value === formData.skillLevel
                )}
                onChange={handleSelectChange}
                options={skillsOptions}
                isClearable
                placeholder="talent skill Level"
                classNamePrefix="react-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    padding: "4px",
                    borderRadius: "8px",
                    borderColor: "#D1D5DB",
                    minHeight: "20px",
                    boxShadow: "none",
                    backgroundColor:
                      document.documentElement.classList.contains("dark")
                        ? "#1B2A41"
                        : "#FAFAFA",
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    padding: "0 4px",
                    backgroundColor:
                      document.documentElement.classList.contains("dark")
                        ? "#1B2A41"
                        : "#FAFAFA",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: document.documentElement.classList.contains("dark")
                      ? "#FAFAFA"
                      : "#1F2937",
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor:
                      document.documentElement.classList.contains("dark")
                        ? "#1B2A41"
                        : "#FFFFFF",
                  }),
                  option: (base, { isFocused, isSelected }) => ({
                    ...base,
                    backgroundColor: isSelected
                      ? document.documentElement.classList.contains("dark")
                        ? "#4B5563"
                        : "#E5E7EB"
                      : isFocused
                      ? document.documentElement.classList.contains("dark")
                        ? "#374151"
                        : "#F3F4F6"
                      : "transparent",
                    color: document.documentElement.classList.contains("dark")
                      ? "#FFFFFF"
                      : "#1F2937",
                  }),
                }}
                className="block w-full text-gray-600 text-xs"
              />
            </div>
            <div className="block w-full">
              <label
                htmlFor="joiningAvalability"
                className="block mb-1 text-[#6B6B6B] dark:text-white text-[12px] ms-1 font-semibold"
              >
                Joining Availability
              </label>
              <Select
                id="joiningAvalability"
                name="joiningAvalability"
                value={joiningAvalability?.find(
                  (option) => option.value === formData.joiningAvalability
                )}
                onChange={handleSelectChange}
                options={joiningAvalability}
                isClearable
                placeholder="Joing Availability"
                classNamePrefix="react-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    padding: "4px",
                    borderRadius: "8px",
                    borderColor: "#D1D5DB",
                    minHeight: "20px",
                    boxShadow: "none",
                    backgroundColor:
                      document.documentElement.classList.contains("dark")
                        ? "#1B2A41"
                        : "#FAFAFA",
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    padding: "0 4px",
                    backgroundColor:
                      document.documentElement.classList.contains("dark")
                        ? "#1B2A41"
                        : "#FAFAFA",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: document.documentElement.classList.contains("dark")
                      ? "#FAFAFA"
                      : "#1F2937",
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor:
                      document.documentElement.classList.contains("dark")
                        ? "#1B2A41"
                        : "#FFFFFF",
                  }),
                  option: (base, { isFocused, isSelected }) => ({
                    ...base,
                    backgroundColor: isSelected
                      ? document.documentElement.classList.contains("dark")
                        ? "#4B5563"
                        : "#E5E7EB"
                      : isFocused
                      ? document.documentElement.classList.contains("dark")
                        ? "#374151"
                        : "#F3F4F6"
                      : "transparent",
                    color: document.documentElement.classList.contains("dark")
                      ? "#FFFFFF"
                      : "#1F2937",
                  }),
                }}
                className="block w-full text-gray-600 text-xs"
              />
            </div>
          </div>

          {/* work preference and salary */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="block w-full">
              <label
                htmlFor="workplacePreference"
                className="block mb-1 text-[#6B6B6B] dark:text-white text-[12px] ms-1 font-semibold"
              >
                Workplace Preference
              </label>
              <Select
                id="workplacePreference"
                name="workplacePreference"
                value={workplacePreference?.find(
                  (option) => option.value === formData.workplacePreference
                )}
                onChange={handleSelectChange}
                options={workplacePreference}
                isClearable
                placeholder="WorkPlace Preference"
                classNamePrefix="react-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    padding: "4px",
                    borderRadius: "8px",
                    borderColor: "#D1D5DB",
                    minHeight: "20px",
                    boxShadow: "none",
                    backgroundColor:
                      document.documentElement.classList.contains("dark")
                        ? "#1B2A41"
                        : "#FAFAFA",
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    padding: "0 4px",
                    backgroundColor:
                      document.documentElement.classList.contains("dark")
                        ? "#1B2A41"
                        : "#FAFAFA",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: document.documentElement.classList.contains("dark")
                      ? "#FAFAFA"
                      : "#1F2937",
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor:
                      document.documentElement.classList.contains("dark")
                        ? "#1B2A41"
                        : "#FFFFFF",
                  }),
                  option: (base, { isFocused, isSelected }) => ({
                    ...base,
                    backgroundColor: isSelected
                      ? document.documentElement.classList.contains("dark")
                        ? "#4B5563"
                        : "#E5E7EB"
                      : isFocused
                      ? document.documentElement.classList.contains("dark")
                        ? "#374151"
                        : "#F3F4F6"
                      : "transparent",
                    color: document.documentElement.classList.contains("dark")
                      ? "#FFFFFF"
                      : "#1F2937",
                  }),
                }}
                className="block w-full text-gray-600 text-xs"
              />
            </div>

            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] dark:text-white text-[12px] ml-1 font-semibold flex items-center gap-2">
                Expected Salary
              </label>

              <input
                name="expectedSalary"
                className="bg-[#FAFAFA] dark:bg-secondaryDarkBg text-xs font-normal text-[#6B6B6B] dark:text-white rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="Expected Salary"
                value={formData.expectedSalary}
                onChange={handleInputChange}
              />
            </div>

          </div>

          <div className="flex justify-end">
            <ButtonForm
              onClick={handleSubmit}
              loading={loading}
              text="Update Talent"
            />
          </div>
        </form>
      </div>
    </>
  );
}
